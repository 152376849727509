import * as React from 'react';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import theme, { COLORS } from '../../../utils/theme';
import LinkWrapper from '../Link';
import Typography from '../../common/Typography';

const useStyles = {
  cardStyle: {
    border: '1px solid' + COLORS.BORDER,
    borderRadius: '4px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '350px',
    height: '100%',
  },
  cardContent: {
    padding: '24px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  cardTitle: {
    marginBottom: '8px',
  },
  title: {
    marginBottom: '0px',
  },
  linkStyle: {
    color: theme.palette.primary.main,
  },
  clickableArea: {
    cursor: 'pointer',
  },
  contentStyle: {
    display: 'flex',
    direction: 'row',
    alignItems: 'center',
    alignContent: 'center'
  },
  iconStyle: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '72px',
    paddingLeft: '7%',
    paddingRight: '1%'
  },
};
export interface CardLayoutProps {
  title: string;
  url: string;
  description?: string;
  image?: string;
  icon?: string;
}
const CardLayout: React.FC<CardLayoutProps> = ({
  title,
  description,
  image,
  url,
  icon,
}) => {
  return (
    <Card
      sx={
        useStyles.cardStyle
      }
    >
      <Box style={useStyles.clickableArea}>
        <LinkWrapper href={url}>
          {image && <img src={image} alt="img" style={{maxWidth:'100%', maxHeight: '100%'}}/>}
          <Box sx={useStyles.contentStyle}>
            {icon && <Box sx={useStyles.iconStyle}>
              <img src={icon} width={32} height={32} />
            </Box>}
            <Box sx={{ width: icon ? '80%' : '100%' }}>
              <CardContent sx={useStyles.cardContent}>
                {title &&
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={description ? useStyles.cardTitle : useStyles.title}
                  >
                    {title}
                  </Typography>
                }
                {description && <Typography type="DESKTOP_BODY_PARAGRAPH">{description}</Typography>}
              </CardContent>
            </Box>
          </Box>
        </LinkWrapper>
      </Box>
    </Card>
  );
};

export default CardLayout;
