import React from 'react';
import { Box } from '@mui/system';
import theme, { useGlobalStyles, COLORS } from '../../../utils/theme';
import { Button, IconButton, Card, Tabs, Tab } from '@mui/material';
import ExpandIcon from '../../../../static/images/expand.svg';
import CollpaseIcon from '../../../../static/images/collapse.svg';
import ExternalLinksIcon from '../../../../static/images/externalLinks.svg';
import { makeStyles } from '@mui/styles';
import RequestResponse from '../../api-ref/RequestResponse';
import Typography from '../../common/Typography';

const useStyles = {
  icon: {
    color: COLORS.SECONDARY_DUSK,
    height: theme.spacing(4),
    width: theme.spacing(4),
    margin: '0px 0px 0px 6px',
  },
  urlContainer: {
    display: 'flex',
    paddingLeft: '21px',
  },
  cardStyles: {
    padding: '4px',
    boxShadow: theme.shadows[11],
  },
  buttonStyle: {
    width: '100%',
    borderRadius: '0px',
    backgroundColor: COLORS.PRIMARY_AIR,
    justifyContent: 'flex-between',
    '&:hover': {
      backgroundColor: COLORS.PRIMARY_AIR,
    },
  },
  cardItemsStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: COLORS.PRIMARY_AIR,
    minHeight: '28px',
  },
  methodContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  externalLinkIcon: {
    width: '16px',
    height: '16px',
    paddingLeft: '10px',
  },
  IconButtonStyle: {
    padding: '0px',
  },
  methodStyle: {
    color: COLORS.SECONDARY_DUSK,
  },
  expandIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '4px',
  },
  cardActionArea: {
    margin: '0px 20px',
  },
  tabsStyle: {
    marginTop: '17px',
    '& .MuiTabs-flexContainer': {
      borderBottom: 1,
      borderColor: theme.palette.grey[300],
    },
  },
  tabStyle: {
    padding: '0px 12px 12px',
    minHeight: 'unset',
    '&.Mui-selected': {
      fontWeight: 700,
      color: COLORS.TEXT_MEDIUM_EMPHASIS,
    },
  },
  codeStyle: {
    marginBottom: '20px',
  },
};

const middleContainerStyle = makeStyles({
  urlStyle: {
    color: COLORS.SECONDARY_MIDNIGHT,
    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
});

export interface APIRequestSampleProps {
  sampleRequest: string;
  requestLang: any;
  sampleResponse: string;
  responseLang: any;
  url: string;
  method: string;
}

const APIRequestSample: React.FC<APIRequestSampleProps> = (props) => {
  const [selected, setSelected] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  let isExternalLinkClick = false;
  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };
  const handleExternalLinkClick = () => {
    isExternalLinkClick = true;
  };
  const handleCollapse = () => {
    if (!isExternalLinkClick) {
      setOpen(!open);
    }
    isExternalLinkClick = false;
  };
  const {
    sampleRequest,
    requestLang,
    sampleResponse,
    responseLang,
    url,
    method,
  } = props;
  return (
    <Box>
      <Card style={useStyles.cardStyles}>
        <Button
          onClick={handleCollapse}
          sx={useStyles.buttonStyle}
          disableRipple
        >
          <Box style={useStyles.cardItemsStyle}>
            <Box style={useStyles.methodContainer}>
              {method && 
                <Box>
                  <Typography
                    type="DESKTOP_CODE_ALLCAPS"
                    style={useStyles.methodStyle}
                  >
                    {method.toUpperCase()}
                  </Typography>
                </Box>
              }
              <Box style={useStyles.urlContainer}>
                {url && 
                  <Box>
                    <Typography
                      type="DESKTOP_CODE_SECONDARY"
                      className={middleContainerStyle().urlStyle}
                    >
                      {url}
                    </Typography>
                  </Box>
                }
                <IconButton
                  onClick={() => handleExternalLinkClick()}
                  style={useStyles.IconButtonStyle}
                >
                  <img
                    src={ExternalLinksIcon}
                    style={useStyles.externalLinkIcon}
                    alt="external_link"
                  />
                </IconButton>
              </Box>
            </Box>
            <Box style={useStyles.expandIcon}>
              {open ? 
                <img src={ExpandIcon} style={useStyles.icon} alt="expand" />
               : 
                <img src={CollpaseIcon} style={useStyles.icon} alt="collapse" />
              }
            </Box>
          </Box>
        </Button>
        <Box style={useStyles.cardActionArea}>
          {open && 
            <Tabs
              value={selected}
              onChange={handleChange}
              sx={useStyles.tabsStyle}
              TabIndicatorProps={{
                style: {
                  top: '31px',
                  color: COLORS.SECONDARY_DUSK,
                  height: '1px',
                },
              }}
            >
              <Tab
                label={
                  <Typography
                    type={
                      selected === 0
                        ? 'DESKTOP_ACTION_SECONDARY_MEDIUM'
                        : 'DESKTOP_ACTION_SECONDARY'
                    }
                  >
                    {requestLang === 'bash' ? 'cURL' : requestLang}
                  </Typography>
                }
                sx={useStyles.tabStyle}
              />
              <Tab
                label={
                  <Typography
                    type={
                      selected === 1
                        ? 'DESKTOP_ACTION_SECONDARY_MEDIUM'
                        : 'DESKTOP_ACTION_SECONDARY'
                    }
                  >
                    {responseLang === 'bash' ? 'cURL' : responseLang}
                  </Typography>
                }
                sx={useStyles.tabStyle}
              />
            </Tabs>
          }
          {open && selected === 0 && 
            <Box style={useStyles.codeStyle}>
              <RequestResponse
                children={sampleRequest}
                className={'language-' + requestLang}
                isAPI={true}
              />
            </Box>
          }
          {open && selected === 1 && 
            <Box style={useStyles.codeStyle}>
              <RequestResponse
                children={sampleRequest}
                className={'language-' + responseLang}
                isAPI={true}
              />
            </Box>
          }
        </Box>
      </Card>
    </Box>
  );
};
export default APIRequestSample;
