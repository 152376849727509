import React from 'react';
import { Table as TableComponent } from '@mui/material';
import theme, { BREAKPOINTS } from '../../../utils/theme';
import { Box } from '@mui/system';

const useStyles = {
  tableStyle: {
    display: 'block',
    overflow: 'auto',
    border: '1px solid' + theme.palette.grey[200],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    margin: '24px 0px',
    maxWidth: 'fit-content',
  },
};

const Table = (props) => {
  return (
    <Box sx={useStyles.tableStyle}>
      <TableComponent {...props}>{props.children}</TableComponent>
    </Box>
  );
};

export default Table;
