const SDKSideNavPriority = {
    'skyflow-react-js': {
        Overview: {
            order: 1
        },
        Core: {
            order: 2,
            child: {
                SkyflowElements: { order: 1 }
            }
        },
        Elements: {
            order: 3,
            child: {
                CardHolderNameElement: { order: 1 },
                CardNumberElement: { order: 2 },
                ComposableContainer: { order: 3 },
                CVVElement: { order: 4 },
                ExpirationDateElement: { order: 5 },
                ExpirationMonthElement: { order: 6 },
                ExpirationYearElement: { order: 7 },
                InputFieldElement: { order: 8 },
                PinElement: { order: 9 },
                RevealElement: { order: 10 }
            }
        },
        Hooks: {
            order: 4,
            child: {
                useCollectContainer: {order: 1},
                useComposableContainer: { order: 2 },
                useMakeSkyflowStyles: { order: 3 },
                useRevealContainer: { order: 4 },
            }
        },
    },
    'skyflow-node': {
        Modules: {
            order: 1
        }
    },
    'skyflow-js': {
        Modules: {
            order: 1,
            child: {
                Skyflow: {order: 1}
            }
        },
        Classes: {
            order: 2,
            child: {
                'Skyflow.default': {order: 1}
            }
        }
    },
    'skyflow-python': {
        Skyflow: {
            order: 1
        }
    },
    'skyflow-android': {
        Overview: {
            order: 1
        },
        Init: {
            order: 2
        },
        Classes: {
            order: 3
        },
        Interfaces: {
            order: 4
        }
    },
};

module.exports = {SDKSideNavPriority};