/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import theme, { COLORS } from '../../../utils/theme';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';
import CodeBlock from '../../common/CodeBlock';
import Typography from '../../common/Typography';
import contentCopyIcon from '../../../../static/images/contentCopyIcon.svg';
import CodeLanguages from '../../../utils/messages/CodeLanguages';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { StyledPre } from '../../docs/MDXComponents';

const useStyles = {
  titleWrapper: { display: 'flex' },
  mimeTypeWrapper: { padding: '11.5px 0px 11.5px 10px' },
  buttonWrapperDefault: { backgroundColor: 'inherit' },
  titleBox: { padding: '9px 0px 9px 12px' },
  iconButtonStyle: { padding: theme.spacing(0) },
  headerRightStyle: { display: 'flex' },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[200],
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    height: '38px',
  },
  menuItems: {
    color: COLORS.SECONDARY_DUSK,
    height: '19px',
    minHeight: 'unset',
    minWidth: '55px',
    width: 'auto',
    padding: '0px',
    marginBottom: '2px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_SECONDARY,
    },
  },
  buttonStyle: {
    ':hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
      marginRight: '0px',
    },
    height: '22px',
    padding: '0px',
    justifyContent: 'flex-end',
  },
  expandedButtonStyle: {
    width: 'auto',
    justifyContent: 'flex-end',
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
      marginRight: '0px',
    },
    height: '22px',
    padding: '0px',
  },
  expandIcon: {
    height: '16px',
    widht: '16px',
    fontSize: '16px',
  },
  dropdownWrapper: {
    display: 'inherit',
    alignItems: 'center',
    margin: '0px 10px',
  },
  buttonWrapper: {
    borderRadius: '4px 4px 0px 0px',
  },
  menuListStyle: {
    '& .MuiList-root.MuiMenu-list': { padding: '2px 4px' },
    '& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
      borderRadius: '0px 0px 4px 4px',
      marginLeft: '4px',
    },
  },
  listItem: {
    padding: '2px 2px 2px 4px',
    color: COLORS.SECONDARY_DUSK,
  },
  selectedItemStyle: {
    paddingRight: '0px',
    paddingLeft: '4px',
    color: COLORS.SECONDARY_DUSK,
  },
  copyIcon: {
    padding: '0px 10px',
    margin: 'auto',
    borderLeft: '1px groove',
    borderLeftColor: theme.palette.grey[600],
  },
};

export interface Item {
  id: string;
  title: string;
  content: string;
}

export interface RequestResponseProps {
  items?: Item[];
  type?: 'response' | 'request';
  className?: string;
  children?: string;
  isJSONFoldable?: boolean;
  snippetId?: string;
  isAPI?: boolean;
  requestExamples?: any;
  parameters?: any;
  onSelect?: (index: number) => void;
  responseExamples?: any;
  inSync?: boolean;
  responseSyncOption?: any;
}

const addCopyTextEventToDataLayer = (id) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'copyTextEventSubmission',
      snippetId: id,
    });
  }
  return '';
};

const generateUniqueId = (type, getCopyText, snippetId, selectedOption,flags) => {
  let uniqueId = snippetId;
  if (navigator.clipboard) {
    navigator.clipboard.writeText(getCopyText());
  }
  if (type === 'response' || type === 'request') {
    uniqueId += '__' + selectedOption;
  }
  if(flags && flags.enableDocAnalyticsEvents === true){
    addCopyTextEventToDataLayer(uniqueId);
  }
};

const prettifyJsonPayload = (payload, baseIndent = 6) => {
  try {
    const parsedPayload = JSON.parse(payload);
    const prettyPayload = JSON.stringify(parsedPayload, null, 2);
    const indentedPayload = prettyPayload
      .split('\n')
      .map((line, index) => {
        return index === 0
          ? line
          : ' '.repeat(baseIndent) + line;
      })
      .join('\n');
    return indentedPayload;
  } catch (error) {
    return payload;
  }
};

const RequestResponse: React.FC<RequestResponseProps> = (props) => {
  const { items, className, children, isJSONFoldable, type, snippetId, isAPI, requestExamples, parameters, onSelect, responseExamples, inSync, responseSyncOption } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [mainHeadings, setMainHeadings] = useState<string[]>([]);
  const [responseAnchorEl, setResponseAnchorEl] = useState<null | HTMLElement>(null);
  let parameterHeadings
  if (!requestExamples?.examples && parameters && Array.isArray(parameters)) {
    parameters?.forEach((param) => {
      if (!requestExamples?.examples && param.examples) {
        parameterHeadings = Object.keys(param.examples);
      }
    });
  }
  const responseOpen = Boolean(responseAnchorEl);

  const handleResponseClick = (event: React.MouseEvent<HTMLElement>) => {
    setResponseAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (requestExamples?.examples) {
      setMainHeadings(Object.keys(requestExamples?.examples));
    } else if (!requestExamples?.examples && parameters && parameterHeadings) {
      setMainHeadings(parameterHeadings);
    }
  }, [requestExamples?.examples]);

  const modifyContentWithExample = () => {
    if (items && items[0] && requestExamples?.example) {
      const currentItem = items[0];
      const modifiedContent = currentItem.content.replace(
        /(-d\s*')([\s\S]*?)(')/,
        `$1${prettifyJsonPayload(JSON.stringify(requestExamples.example, null, 2))}$3`
      );
      currentItem.content = modifiedContent;
    }
  };

  useEffect(() => {
    modifyContentWithExample();
  }, [requestExamples]);

  const getModifiedContent = (selectedIndex) => {
    let currentItem;
    if(items) currentItem = items[0];
    const urlMatch = currentItem.content.match(/https:\/\/[^\s]+/);
    let url = urlMatch ? urlMatch[0] : '';
    let firstKey, selectedExample;
    if (requestExamples?.examples) {
      firstKey = Object.keys(requestExamples?.examples)[selectedIndex];
      selectedExample = requestExamples?.examples[firstKey];
    }
    parameters?.forEach((param) => {
      if (param.examples && selectedExample) {
        const exampleValue = param.examples[firstKey]?.value;
        if (exampleValue) {
          const regex = new RegExp(`{${param.name}}`, 'g');
          url = url.replace(regex, exampleValue);
        }
      }
    });
    if (requestExamples?.examples) {
      const data = JSON.stringify(selectedExample.value, null, 2);
      const exampleData = prettifyJsonPayload(data);
      return currentItem.content
        .replace(/https:\/\/[^\s]+/, url)
        .replace(/(-d\s*')([\s\S]*?)(')/, `$1${exampleData}$3`);
    }
    return currentItem ? currentItem.content : '';
  };

  const getModifiedParam = (selectedIndex) => {
    let currentItem;
    if (items) currentItem = items[0];
    const urlMatch = currentItem.content.match(/https:\/\/[^\s]+/);
    let url = urlMatch ? urlMatch[0] : '';
    if (Array.isArray(parameters)) {
      parameters?.forEach((param) => {
        let exampleValue;
        if (!requestExamples?.examples && param.examples) {
          const paramExampleKeys = Object.keys(param.examples);
          const paramExampleKey = paramExampleKeys[selectedIndex];
          exampleValue = param.examples[paramExampleKey];
        }
        if (exampleValue) {
          const regex = new RegExp(`{${param.name}}`, 'g');
          url = url.replace(regex, exampleValue.value);
        }
      });
    }
    if (parameters) {
      return currentItem.content
        .replace(/https:\/\/[^\s]+/, url)
    }
    return currentItem ? currentItem.content : '';
  };

  const getModifiedResponse = (selectedIndex) => {
    let currentItem 
    if(items) currentItem = items[selectedIndex];
    const statusCodes = Object.keys(responseExamples);
    const statusCode = statusCodes[selectedIndex];
    if (responseExamples && responseExamples[statusCode] && items) {
      const responseExample = responseExamples[statusCode]?.content?.['application/json'];
      let exampleData
      const selectedStatusCode = items[selectedIndex]?.title;
      const responseExamplesForStatus = responseExamples?.[selectedStatusCode]?.content?.['application/json']?.examples;
      if (responseExamplesForStatus) {
        const responseKeys = Object.keys(responseExamplesForStatus);
        if (inSync && responseSyncOption && selectedStatusCode === '200') {
          responseOptions = responseKeys[responseSyncOption];
          exampleData = responseExample?.examples[responseOptions]?.value;
        } else {
          responseOptions = responseKeys[responseSelectedIndex];
        }
      }
      if (!exampleData && responseExample?.examples) {
        exampleData = responseExample?.examples[responseOptions]?.value;
      }
      if (!exampleData) {
        return currentItem.content;
      }
      const modifiedContent = JSON.stringify(exampleData, null, 2);

      return modifiedContent;
    }
    return currentItem.content;
  };
  const flags = useFlags();
  const open = Boolean(anchorEl);
  let code = '';
  let lang = '';
  let option = '';
  const parseTitle = (type?) => {
    let lang = '';
    if (!type) {
      if (items && !requestExamples?.examples && !responseExamples && !parameters) {
        lang = items[selectedIndex]?.id;
      } else if (requestExamples?.examples) {
        lang = Object.keys(requestExamples?.examples)[selectedIndex];
      } else if (!requestExamples?.examples && parameters) {
        lang = parameterHeadings[selectedIndex];
      } else if (className) {
        lang = className;
      }
    } else {
      lang = type;
    }
    if (lang?.includes('_')) {
      lang = lang.split('_')[1];
    } else if (lang?.includes('-')) {
      lang = lang.split('-')[1];
    }
    return CodeLanguages[lang] ? CodeLanguages[lang] : lang;
  };
  if (items) {
    option = parseTitle(items[0]?.id);
  }
  const [selectedOption, setSelectedOption] = React.useState<number | string>(
    option,
  );

  let responseOptions = '';
  const [responseSelectedIndex, setResponseSelectedIndex] = useState(0);
  useEffect(() => {
    if(selectedOption !== '200') setResponseSelectedIndex(0);
  }, [selectedOption]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (items) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    statusCode: number,
  ) => {
    setSelectedOption(statusCode);
    setSelectedIndex(index);
    if (inSync) {
      if (onSelect) {
        onSelect(index);
      };
    }
    setAnchorEl(null);
  };

  const handleResponseMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setResponseSelectedIndex(index);
    setResponseAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResponseClose = () => setResponseAnchorEl(null);
  const parseLang = (type) => {
    if (type?.includes('_')) {
      return type.split('_')[0];
    } else {
      return type;
    }
  };

  let combinedItems;
  if(items) {
    combinedItems = [...items, ...mainHeadings?.map((heading) => ({ id: heading }))];
  }
  const filteredItems = combinedItems ? combinedItems.filter(item => parseTitle(item.id) !== 'cURL') : [];
  const buildButton = () => {
    return (
      <Box sx={open ? useStyles.buttonWrapper : {}}>
        <Button
          variant="text"
          disableElevation
          disableRipple
          onClick={handleClickListItem}
          endIcon={items && filteredItems.length > 0 ? <ExpandMoreIcon sx={useStyles.expandIcon} /> : <></>}
          sx={open ? useStyles.expandedButtonStyle : useStyles.buttonStyle}
          aria-label="language"
          disabled={filteredItems.length === 0}
        >
          <Typography
            type="DESKTOP_CODE_PRIMARY"
            sx={useStyles.selectedItemStyle}
          >
            {filteredItems[selectedIndex]?.id ? parseTitle(filteredItems[selectedIndex].id) : combinedItems && combinedItems[0]?.id=== 'shell_curl' ? null : parseTitle()
            }
          </Typography>
        </Button>
      </Box>
    );
  };

  const buildResponseButton = () => {
    let selectedStatusCode;
    if(items){
      selectedStatusCode = items[selectedIndex]?.title;
    }
    const responseExamplesForStatus = responseExamples?.[selectedStatusCode]?.content?.['application/json']?.examples;
    if (!responseExamplesForStatus) {
      return null;
    }
    const responseKeys = Object.keys(responseExamplesForStatus);
    let responseOption;
    if (inSync && responseSyncOption && selectedStatusCode === '200') {
      responseOption = responseKeys[responseSyncOption];
    } else {
      responseOption = responseKeys[responseSelectedIndex];
    }
    return (
      <Box>
        <Button
          variant="text"
          disableElevation
          disableRipple
          onClick={handleResponseClick}
          endIcon={responseKeys.length > 1 ? <ExpandMoreIcon sx={useStyles.expandIcon} /> : <></>}
          disabled={responseKeys.length === 1}
          sx={{':hover': {backgroundColor: 'transparent'}}}
        >
          <Typography
            type="DESKTOP_CODE_PRIMARY"
            sx={useStyles.selectedItemStyle}
          >
            {responseOption}
          </Typography>
        </Button>
        <Menu
          anchorEl={responseAnchorEl}
          open={responseOpen}
          onClose={handleResponseClose}
          MenuListProps={{
            role: 'listbox',
          }}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transitionDuration={{
            appear: 0,
            enter: 0,
            exit: 0,
          }}
          sx={useStyles.menuListStyle}
        >
          {responseKeys.map((key, index) => (
            <MenuItem
              key={key}
              selected={index === responseSelectedIndex}
              onClick={(event) => handleResponseMenuItemClick(event, index)}
              sx={useStyles.menuItems}
            >
              <Typography
                type="DESKTOP_CODE_SECONDARY"
                sx={useStyles.listItem}
              >
                {key}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };
  if (children) {
    code = children.trim();
    if (children.includes('{/script}')) {
      const content = children.trim();
      code = content.replace(/\{script(.*?)\}/g, '<script$1>').replace(/\{\/script\}/g, '</script>');
    }
    lang = '';
    if (className) {
      lang = className.replace(/language-/, '');
    } else {
      lang = 'bash';
    }
    lang = CodeLanguages[lang] ? CodeLanguages[lang] : lang;
  }
  if (typeof code !== 'string' && lang === 'json') {
    code = JSON.stringify(code);
  }
  if (!items && !children) {
    return <></>;
  }
  const getCopyText = () => {
    if (items && !requestExamples?.examples && !responseExamples) {
      return items[selectedIndex].content;
    } else if (requestExamples?.examples) {
      return getModifiedContent(selectedIndex)
    } else if (responseExamples) {
      return getModifiedResponse(selectedIndex)
    } else if (code) {
      return code;
    } else {
      return '';
    }
  };
  const buildCopyButton = () => {
    return (
      <Box sx={useStyles.copyIcon}>
        <IconButton
          onClick={() =>
            generateUniqueId(type, getCopyText, snippetId, selectedOption, flags)
          }
          sx={useStyles.iconButtonStyle}
        >
          <img src={contentCopyIcon} width="16px" height="16px" alt="copy" />
        </IconButton>
      </Box>
    );
  };
  return (
    <StyledPre>
      <Box sx={useStyles.headerBox}>
        <Box sx={useStyles.titleWrapper}>
          {props.type &&
            <Box sx={useStyles.titleBox}>
              <Typography type="DESKTOP_CODE_ALLCAPS" component="span">
                {props.type === 'request'
                  ? apiRefMessages.request
                  : apiRefMessages.response}
              </Typography>
            </Box>
          }
        </Box>
        <Box sx={useStyles.headerRightStyle}>
          <Box sx={useStyles.dropdownWrapper}>
            {buildResponseButton()}{buildButton()}
            {items &&
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  role: 'listbox',
                }}
                elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transitionDuration={{
                  appear: 0,
                  enter: 0,
                  exit: 0,
                }}
                sx={useStyles.menuListStyle}
              >
                {filteredItems.map((item, index) =>
                  <MenuItem
                    key={item.id}
                    selected={index === selectedIndex}
                    onClick={(event) =>
                      handleMenuItemClick(event, index, parseTitle(item.id))
                    }
                    sx={useStyles.menuItems}
                  >
                    <Typography
                      type="DESKTOP_CODE_SECONDARY"
                      sx={useStyles.listItem}
                    >
                      {parseTitle(item.id)}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            }
          </Box>
          {buildCopyButton()}
        </Box>
      </Box>
      <CodeBlock
        code={items && !requestExamples?.examples && !responseExamples && !parameters
          ? items[selectedIndex]?.content
          : requestExamples?.examples
            ? getModifiedContent(selectedIndex) : !responseExamples && parameters ? getModifiedParam(selectedIndex) : responseExamples ? getModifiedResponse(selectedIndex)
              : code}
        lang={parseLang(items ? requestExamples?.examples || parameters ? 'shell_curl' : items[selectedIndex]?.id : lang)}
        dependencies={[selectedIndex]}
        isJSONFoldable={isJSONFoldable}
        isAPI={isAPI}
      />
    </StyledPre>
  );
};
export default RequestResponse;
