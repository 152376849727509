import React from 'react';

interface Context {
  isloading: boolean;
  setIsLoading: (val:any) => void;
  history: string[];
  setHistory: (val:any) => void;
  isAuthenticated: string[];
  setIsAuthenticated: (val:any) => void;
  rightNavData: string[];
  setRightNavData: (val:any) => void;
  previousLocation: string[];
  setPreviousLocation: (val:any) => void;
}

export const AppContext = React.createContext<Context>({isloading:true, setIsLoading:(val)=>{}, history:[], setHistory:(val)=>{}, isAuthenticated:[], setIsAuthenticated:(val)=>{}, rightNavData:[], setRightNavData:(val)=>{}, previousLocation:[], setPreviousLocation:(val)=>{}});

export default function AppContextProvider({children}) {
  const [state, setState] = React.useState(true);
  const [history, setHistory] = React.useState([]);
  const [isAuthenticated, setIsAuthenticated] = React.useState([]);
  const [rightNavData, setRightNavData] = React.useState([]);
  const [previousLocation, setPreviousLocation] = React.useState([]);
  return (
    <React.Fragment>
      <AppContext.Provider value={{isloading: state, setIsLoading: setState, history, setHistory, isAuthenticated, setIsAuthenticated, rightNavData, setRightNavData, previousLocation, setPreviousLocation}}>
        {children}
      </AppContext.Provider>
    </React.Fragment>
  );
}
