import { createTheme } from '@mui/material/styles';

export const pxToRem = (fontSize) => {
  return `${fontSize / 16}rem`;
};

export interface ThemeMap {
  [name: string]: string;
}
export interface SizeMap {
  [name: string]: number;
}
export const BREAKPOINTS: ThemeMap = {
  ANDROID: '@media (max-width:374px)',
  MOBILE: '@media (max-width: 1023px)',
  DESKTOP: '@media (min-width: 1024px) and (max-width: 1366px)',
  DEFAULT: '@media (max-width:1366px)',
  CUSTOM: '@media (max-width:767px)',
  WIDTH_1024: '@media(width:1024px)',
  MEDIA_MIN_600: '@media (min-width: 600px)',
  MD: '@media(min-width:900px)',
  MEDIA_MAX_550: '@media (max-width:550px)',
  MEDIA_MIN_1400: '@media (min-width:1400px)',
  MEDIA_MIN_MAX: '@media (min-width: 584px) and (max-width: 1023px)',
  MEDIA_MIN_1367: '@media (min-width: 1367px) and (max-width: 1399px)',
  MEDIA_MIN_1200: '@media (min-width: 1200px) and (max-width: 1399px)',
  MEDIA_MIN_1024: '@media (min-width: 1024px) and (max-width: 1100px)',
  MEDIA_MAX_1199: '@media (min-width: 1024px) and (max-width: 1199px)',
  MEDIA_MIN_1101: '@media (min-width: 1101px) and (max-width: 1366px)',
  MOBILE_MAX_460: '@media (min-width: 375px) and (max-width:460px)',
  MEDIA_MIN_768: '@media (min-width: 768px) and (max-width: 1023px)',
  MEDIA_MIN_426: '@media (min-width: 426px) and (max-width: 600px)',
  MEDIA_MIN_532: '@media (min-width: 532px) and (max-width: 767px)',
  MEDIA_MAX_615: '@media (min-width: 532px) and (max-width: 615px)',
  MEDIA_MIN_420: '@media (min-width: 420px) and (max-width: 531px)',
  MEDIA_MIN_375: '@media (min-width: 375px) and (max-width: 419px)',
};

export const MOBILE_SPACING: ThemeMap = {
  MARGIN: '40px',
};
export const WIDTH_1024_SPACING: ThemeMap = {
  MARGIN: '80px',
};
export const COLORS: ThemeMap = {
  TEXT_HIGH_EMPHASIS: '#403E6B',
  TEXT_MEDIUM_EMPHASIS: '#3E3E4E',
  TEXT_LOW_EMPHASIS: '#A3A3A3',
  LINK_COLOR: '#686DC4',
  PRIMARY_DAWN: '#FAF79B',
  PRIMARY_AIR: '#DEEFFF',
  PRIMARY_FRONT: '#A2E0CE',
  PRIMARY_DAY: '#F7866D',
  PRIMARY_WHITE: '#FFFFFF',
  SECONDARY_DUSK: '#686DC4',
  SECONDARY_STORM: '#007364',
  SECONDARY_NIGHT: '#403E6B',
  SECONDARY_MIDNIGHT: '#1B1B2E',
  SECONDARY_LUNAR: '#3E3E4E',
  CODE_PRIMARY: '#007364',
  CODE_SECONDARY: '#403E6B',
  CODE_TERITARY: '#686DC4',
  BACKGROUND_PRIMARY: '#FFFFFF',
  BACKGROUND_SECONDARY: '#F7F7F7',
  BACKGROUND_TERTIARY: '#F5F7FA',
  TEXT_BODY: '#4F535B',
  TEXT_SECONDARY: '#8B8B8B',
  TEXT_TERTIARY: '#6267C4', //used for sign-in
  OUTLINE_COLOR: '#A6A6BF',
  PRIMARY_ACTIONS: '#565CC4',
  BACKGROUND_ACTION_BAR: '#00000033',
  TEXT_DELETE: '#BF3636',
  BORDER:'#0000002E'
};

export const FONTS: ThemeMap = {
  HEADING_FONT: 'Cosmica',
  CODE_FONT: 'GT Pressura Mono',
  BODY_FONT: 'Inter',
};

export const FONT_SIZES: SizeMap = {
  CODE_SECONDARY: 14,
  CODE_SECONDARY_MOBILE: 12,
};
export const FONT_LINE_HEIGHT: SizeMap = {
  CODE_SECONDARY: 15.4,
  CODE_SECONDARY_MOBILE: 13.2,
  CODE_BLOCK: 1.45,
};
export const useGlobalStyles = {
  DESKTOP_BODY_PARAGRAPH: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(16),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '25.6px',
    color: COLORS.TEXT_BODY,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '21px',
    },
  },
  DESKTOP_BODY_PARAGRAPH_MEDIUM: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '25.6px',
    letterSpacing: '0.01rem',
    color: COLORS.TEXT_BODY,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '21px',
    },
  },
  DESKTOP_BODY_SMALL_TEXT: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(14),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16.94px',
    letterSpacing: '0.01rem',
    color: COLORS.TEXT_BODY,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(12),
      lineHeight: '17px',
    },
  },
  DESKTOP_BODY_LINKS: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(16),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '25.6px',
    letterSpacing: '0.01rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '21px',
    },
  },
  DESKTOP_ACTION_PRIMARY: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(14),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16.94px',
  },
  DESKTOP_ACTION_PRIMARY_BOLD: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(14),
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '16.94px',
  },
  DESKTOP_ACTION_SECONDARY: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(14),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16.94px',
    letterSpacing: '0.01rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(12),
      lineHeight: '17px',
    },
  },
  DESKTOP_ACTION_SECONDARY_MEDIUM: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(14),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16.94px',
    letterSpacing: '0.01rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(12),
      lineHeight: '17px',
    },
  },
  DESKTOP_CODE_PRIMARY: {
    fontFamily: FONTS.CODE_FONT,
    fontSize: pxToRem(16),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '22.4px',
    letterSpacing: '0.02rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '19.6px',
    },
  },
  DESKTOP_CODE_SECONDARY: {
    fontFamily: FONTS.CODE_FONT,
    fontSize: pxToRem(FONT_SIZES.CODE_SECONDARY),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: `${FONT_LINE_HEIGHT.CODE_SECONDARY}px`,
    letterSpacing: '0.02rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: '12px',
    },
  },
  DESKTOP_CODE_ALLCAPS: {
    fontFamily: FONTS.CODE_FONT,
    fontSize: pxToRem(12),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '13.2px',
    letterSpacing: '0.04rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(12),
      lineHeight: '13.2px',
    },
  },
  DESKTOP_HEADING_ALLCAPS: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(14),
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: '18.2px',
    letterSpacing: '0.06rem',
  },
  SMALL_LIST_HEADER: {
    fontFamily: FONTS.HEADING_FONT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: '26px',
    color: COLORS.SECONDARY_MIDNIGHT,
  },
  REGULAR_TABLE_CELL: {
    fontFamily: FONTS.BODY_FONT,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: '20px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
  },
  SMALL_SUB_HEADER: {
    fontFamily: FONTS.HEADING_FONT,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: '20px',
    color: COLORS.PRIMARY_ACTIONS,
  },
  MEDIUM_SUB_HEADER: {
    fontFamily: FONTS.HEADING_FONT,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: '24px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
  },
  INPUT_LABEL: {
    fontFamily: FONTS.BODY_FONT,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: '15px',
    color: COLORS.SECONDARY_MIDNIGHT,
  },
  DESKTOP_BUTTON_TEXT: {
    fontFamily: FONTS.CODE_FONT,
    fontSize: pxToRem(17),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.01rem',
    color: COLORS.TEXT_BODY,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '21px',
    },
  },
  DESKTOP_BODY_TEXT: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(40),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '60px',
    letterSpacing: 'normal',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(24),
      lineHeight: '31.2px',
    },
  },
  DESKTOP_BODY_SUBTITLE: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(18),
    fontWeight: 400,
    opacity: 0.82,
    fontStyle: 'normal',
    lineHeight: '27px',
    letterSpacing: 'normal',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(15),
      lineHeight: '21px',
    },
  },
  DESKTOP_HEADING: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(19),
    fontWeight: 700,
    opacity: 0.92,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    lineHeight: '29px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(15),
      lineHeight: '21px',
    },
  },
  DESKTOP_TITLE: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(13),
    fontWeight: 700,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    lineHeight: '25px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    textTransform: 'uppercase'
  },
  DESKTOP_BUTTON: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(17),
    fontWeight: 400,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    lineHeight: '27px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
  },
  DESKTOP_BODY_HEADING: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(19),
    fontWeight: 400,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    lineHeight: '29px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(15),
      lineHeight: '21px',
    },
  },
  DESKTOP_BODY_TITLE: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(17),
    fontWeight: 400,
    opacity: 0.92,
    fontStyle: 'normal',
    lineHeight: '27px',
    letterSpacing: 'normal',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '21px',
    },
  },
  DESKTOP_SUBTITLE: {
    fontFamily: FONTS.BODY_FONT,
    fontSize: pxToRem(16),
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: COLORS.TEXT_BODY,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(15),
      lineHeight: '21px',
    },
  },
  DESKTOP_TITLE_HEADING: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(18),
    fontWeight: 400,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    lineHeight: '29px',
    color: COLORS.TEXT_HIGH_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(16),
      lineHeight: '21px',
    },
  },
  ANNOUNCEMENT_TITLE: {
    fontFamily: FONTS.HEADING_FONT,
    fontSize: pxToRem(14),
    fontWeight: 400,
    opacity: 0.92,
    fontStyle: 'normal',
    lineHeight: '27px',
    letterSpacing: '0.14px',
    color: COLORS.SECONDARY_MIDNIGHT,
    [BREAKPOINTS.MOBILE]: {
      fontSize: pxToRem(14),
      lineHeight: '21px',
    },
  },
};

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#686DC4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#403E6B',
    },
    common: {
      white: '#ffffff',
    },
    background: {
      default: '#fafbfc',
      paper: '#ffffff',
    },
    text: {
      primary: COLORS.TEXT_HIGH_EMPHASIS,
      secondary: COLORS.TEXT_MEDIUM_EMPHASIS,
    },
    grey: {
      100: '#F7F7F7', //3%
      200: '#F0F0F0', //6%
      300: '#E8E8E8', //9%
      400: '#E0E0E0', //12%
      500: '#D9D9D9', //15%
      600: '#D1D1D1', //18%
      700: '#787575', //used for footer parent color is grey 18%
    },
    action: {
      hover: '#eeeeee',
      hoverOpacity: 0.2,
    },
  },
  spacing: 4,
  shadows: [
    'none',
    '0 2px 4px 0 rgba(48, 49, 51, 0.1)', // paper
    '0 1px 3px 0 rgba(48, 49, 51, 0.1)', // button
    '0 1px 3px 0 rgba(0, 0, 0, 0.04)', // paper
    '0 1px 2px 0 rgba(0, 0, 0, 0.08)', // outlined button
    '1px 0 0 0 #f1f1f3',
    '0 1px 0 0 #eaedf3', //menu item
    '0 2px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.02)', //card
    '0 1px 1px 0 rgba(19,31,21,0.1)',
    '0 0 11px rgb(158,158,158)', //card - hover
    '0 4px 8px 0 rgba(48,49,51,0.1)', // card hover
    '0px 0px 15px rgba(0, 0, 0, 0.06)', //card
    '0px 0px 15px rgba(0, 0, 0, 0.14)', //tab container
    '0px 0px 30px rgba(0, 0, 0, 0.1)', //card hover
    '0px 12px 16px 0px rgba(0, 0, 0, 0.06)', //search suggestion
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  transitions: {
    duration: {
      enteringScreen: 700,
      leavingScreen: 500,
    },
  },
  typography: {
    fontFamily: FONTS.HEADING_FONT,
    h1: {
      fontSize: pxToRem(28),
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '36.4px',
      letterSpacing: 'normal',
      color: COLORS.TEXT_HIGH_EMPHASIS,
      [BREAKPOINTS.MOBILE]: {
        fontSize: pxToRem(24),
        lineHeight: '31.2px',
      },
    },
    h2: {
      fontSize: pxToRem(24),
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '31.2px',
      letterSpacing: 'normal',
      color: COLORS.TEXT_HIGH_EMPHASIS,
      [BREAKPOINTS.MOBILE]: {
        fontSize: pxToRem(20),
        lineHeight: '26px',
      },
    },
    h3: {
      fontSize: pxToRem(22),
      fontWeight: 600,
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '28.6px',
      color: COLORS.TEXT_HIGH_EMPHASIS,
      [BREAKPOINTS.MOBILE]: {
        fontSize: pxToRem(16),
        lineHeight: '20.4px',
      },
    },
    h4: {
      fontSize: pxToRem(20),
      fontWeight: 600,
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '26px',
      color: COLORS.TEXT_HIGH_EMPHASIS,
      [BREAKPOINTS.MOBILE]: {
        fontSize: pxToRem(16),
        lineHeight: '20.8px',
      },
    },
    h5: {
      fontSize: pxToRem(18),
      fontWeight: 600,
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '23.4px',
      color: COLORS.TEXT_HIGH_EMPHASIS,
      [BREAKPOINTS.MOBILE]: {
        fontSize: pxToRem(16),
        lineHeight: '20.8px',
      },
    },
    h6: {
      fontSize: pxToRem(16),
      fontWeight: 600,
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '20.8px',
      color: COLORS.TEXT_HIGH_EMPHASIS,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: COLORS.LINK_COLOR,
          underline: 'none',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '24px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: FONTS.CODE_FONT,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
});

export default theme;
