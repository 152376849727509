import { Box } from '@mui/system';
import React from 'react';

const useStyles = {
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px 0px',
  },
};

const Image = (props) => {
  return (
    <Box sx={useStyles.boxStyle} component="span">
      <img {...props} style={{ maxWidth: '100%' }} />
    </Box>
  );
};

export default Image;
