import React, { useRef, useState, useEffect } from 'react';
import { Box, styled } from '@mui/system';
import theme, {
  BREAKPOINTS,
  COLORS,
  FONT_LINE_HEIGHT,
} from '../../../utils/theme';
import JsonToHTML from './JsonToHtml';
import Typography from '../../common/Typography';
import parse from 'html-react-parser';
import { lowlight } from 'lowlight';
import { toHtml } from 'hast-util-to-html';
import { calculateCodeBlockLineHeight } from '../../../utils/helper';

const useStyles = {
  lineNoWrapper: {
    minWidth: theme.spacing(10),
    display: 'inline-grid',
    borderBottomLeftRadius: 'inherit',
  },
  snippetWrapper: {
    display: 'flex',
    borderBottomRightRadius: 'inherit',
    flex: '0 0 100%',
  },
  root: {
    display: 'flex',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  codeWrapper: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    borderBottomRightRadius: 'inherit',
  },
  codeStyle: {
    lineHeight: FONT_LINE_HEIGHT.CODE_BLOCK,
  },
};
const Pre = styled('pre')({
  margin: theme.spacing(0),
  backgroundColor: theme.palette.grey[100],
  borderBottomRightRadius: 'inherit',
  padding: '16px 27px 16px 16px',
  overflow: 'auto',
  width: '100%',
});
export const LineNoPre = styled('pre')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 10px',
  margin: theme.spacing(0),
  color: theme.palette.grey[600],
  backgroundColor: theme.palette.grey[100],
  borderBottomLeftRadius: 'inherit',
});
export const LineNo = styled('span')({
  display: 'table-cell',
  minWidth: theme.spacing(5),
  userSelect: 'none',
  textAlign: 'right',
});
export interface CodeBlockProps {
  code: string;
  lang: any;
  className?: string;
  children?: string;
  dependencies?: any;
  isJSONFoldable?: boolean;
  isAPI?: boolean;
}

const CodeBlock: React.FC<CodeBlockProps> = (props) => {
  let { code, lang } = props;
  const {isAPI} = props;
  code = isAPI ? decodeURIComponent(code) : code;
  const { className, children, dependencies, isJSONFoldable } = props;
  const inputEl = useRef(null);
  const [count, setCount] = useState(0);
  if (typeof code !== 'string' && lang === 'json') {
    code = JSON.stringify(code);
  }
  if (children) {
    code = children.trim();
    if (className) {
      lang = className.replace(/language-/, '');
    } else {
      lang = 'bash';
    }
  }
  if (lang === 'shell') {
    lang = 'bash';
  }
  const calculateLineNum = () => {
    setTimeout(() => {
      setCount(0);
      const current: any = inputEl.current;
      if (current) {
        const height = current.clientHeight - 32;
        const length = Math.round(height / calculateCodeBlockLineHeight());
        if (length > 0) {
          setCount(length);
        }
      }
    }, 0);
  };
  useEffect(() => {
    if (lang !== 'json' || !isJSONFoldable) {
      calculateLineNum();
    }
  }, [...dependencies, inputEl.current]);
  if (lang === 'json' && isJSONFoldable) {
    return (
      <Box sx={useStyles.root}>
        <JsonToHTML
          json={JSON.parse(code)}
          dependencies={dependencies}
          maxExpandLevel={1}
        ></JsonToHTML>
      </Box>
    );
  }

  let tree;
  if (lowlight) {
    tree = lowlight.highlight(lang, code);
  }
  return (
    <Box style={useStyles.root}>
      <Box sx={useStyles.lineNoWrapper}>
        <LineNoPre>
          {[...Array(count)].map((x, index) => 
            <LineNo key={index}>
              <Typography
                type="DESKTOP_CODE_SECONDARY"
                align="right"
                component="span"
                sx={useStyles.codeStyle}
              >
                {index + 1}
              </Typography>
            </LineNo>
          )}
        </LineNoPre>
      </Box>
      <Box sx={useStyles.codeWrapper}>
        <Box sx={useStyles.snippetWrapper}>
          <Pre ref={inputEl}>
            <Typography
              className={'hljs language-' + lang}
              component="span"
              type="DESKTOP_CODE_SECONDARY"
              sx={useStyles.codeStyle}
            >
              {tree && parse(toHtml(tree))}
            </Typography>
          </Pre>
        </Box>
      </Box>
    </Box>
  );
};
export default CodeBlock;
