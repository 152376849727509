import React from 'react';
import { Breadcrumbs } from '@mui/material';
import LinkWrapper from '../Link';
import Typography from '../Typography';
import { COLORS } from '../../../utils/theme';

interface BreadcrumbProps {
  links: any;
  onClick?: () => void;
}
const useStyles = {
  linkColor: {
    color: COLORS.TEXT_SECONDARY,
  },
  seperatorStyle: {
    '& .MuiBreadcrumbs-separator': {
      color: COLORS.TEXT_SECONDARY,
    },
  },
};
const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { links, onClick } = props;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      onClick={onClick}
      separator="›"
      sx={useStyles.seperatorStyle}
    >
      {links.map((link) => {
        return (
          <Typography
            type="DESKTOP_BODY_SMALL_TEXT"
            key={link.href}
            sx={useStyles.linkColor}
          >
            {link.title}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
