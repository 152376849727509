import React from 'react';
import { Helmet } from 'react-helmet';
import skyflowIcon from '../../../../static/favicon.png';
import homePageMessages from '../../../utils/messages/en/homePageMessages';
import { useFlags } from 'gatsby-plugin-launchdarkly';
interface SeoProps {
  title?: string;
  description?: string;
  image?: string;
  twitterCard?: string;
  article?: boolean;
  docType?: string;
}
declare global {
  interface Window {
      dataLayer:any;
  }
}

const SEO: React.FC<SeoProps> = ({
  title,
  description,
  image,
  article,
  docType,
}) => {
  let href;
  if(typeof window !== 'undefined')
  {
    href = window.location.origin + window.location.pathname;
  }
  const titleTemplate = '%s · Skyflow Documentation';
  const twitterUsername = '';
  const flags = useFlags();
  const addDocTypeTodataLayer = (docType) => {
    if( typeof window !== 'undefined' && window.dataLayer){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'docTypeSubmission',
      docType:docType,
    });
  }
    return '';
  };
  const seo = {
    title: title || 'Skyflow Documentation',
    description: description || homePageMessages.description,
    image: skyflowIcon,
    url: href,
    docType: docType || '',
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang: 'en' }}
    >
       {flags.enableDocAnalyticsEvents===true && addDocTypeTodataLayer(seo.docType)}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && 
        <meta property="og:description" content={seo.description} />
      }
      {seo.docType && <meta property="og:docType" content={seo.docType} />}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && 
        <meta name="twitter:creator" content={twitterUsername} />
      }

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && 
        <meta name="twitter:description" content={seo.description} />
      }

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;

SEO.defaultProps = {
  title: undefined,
  description: undefined,
  image: undefined,
  article: false,
};