import * as React from 'react';
import { Box, styled } from '@mui/system';
import Typography from '../Typography';
import theme, {
  BREAKPOINTS,
  MOBILE_SPACING,
  COLORS,
} from '../../../utils/theme';
import { Button, Divider, Paper } from '@mui/material';
import { OktaAuth } from '@okta/okta-auth-js';
import LoadingState from '../LoadingState';
import Info from '../../../../static/images/info.svg';
import globalMessages from '../../../utils/messages/en/globalMessages';
import LinkWrapper from '../Link';
import routes from '../../../docs-config/src';
import { navigate } from 'gatsby';
import ExternalLinkSvg from '../../../../static/images/openStudio.svg';
import LoginImage from '../../../../static/images/login-illustration.svg';
import SEO from '../Seo';
import { AppContext } from '../../AppContextProvider';
import { externalLinks } from '../../../utils/constants';
import fileIcon from '../../../../static/images/file-text.svg';
import { useMediaQuery } from '@mui/material';

const useStyles = {
  outerBox: {
    display: 'flex',
    flex: '1 1 0%',
    width: '100%',
  },
  innerBox: {
    display: 'flex',
  },
  centerBoxWithRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      width: 'calc(100% - 410px)',
      paddingLeft: theme.spacing(10),
    },
  },
  paperStyle: {
    backgroundColor: COLORS.PRIMARY_AIR,
    display: 'flex',
    marginBottom: '16px',
    padding: theme.spacing(3),
    maxWidth: '644px',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      maxWidth: 'calc(100% - 20px)',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    height: theme.spacing(6),
    width: theme.spacing(6),
    paddingRight: '8px',
  },
  centerBoxWithNoRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: '410px',
      margin: 'auto',
    },
  },
  imgStyle: {
    padding: theme.spacing(3),
  },
  getAccessStyle: { 
    borderRadius: 1,
    marginLeft: '12px',
    backgroundColor: 'white'
  },
  textStyle: {
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    maxWidth: 'calc(100% - 124px)',
  },
  linkStyle: {
    textDecoration: 'none',
    color: COLORS.LINK_COLOR,
    overflowWrap: 'break-word',
  },
  openStudioStyle: {
    backgroundColor: 'transparent',
    borderRadius: '100px',
    paddingLeft: '20px',
    minWidth: '170px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '0px 18px',
    alignItems: 'center',
    minHeight: '34px',
    maxHeight: '34px',
    ':hover': {
      backgroundColor: 'transparent',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  mobileOpenStudioStyle: {
    minWidth: '170px',
    backgroundColor: COLORS.BACKGROUND_TERTIARY,
    borderRadius: '100px',
    paddingLeft: '20px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '0px 21px',
    alignItems: 'center',
    minHeight: '37px',
    maxHeight: '37px',
    width: '106px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_TERTIARY,
    },
    marginBottom: '29px',
    marginLeft: '21px',
    [BREAKPOINTS.DESKTOP]: {
      display: 'none',
    },
  },
  boxstyle: {
    width: '100%',
    [BREAKPOINTS.MEDIA_MIN_1400]: {
    },
    [BREAKPOINTS.DESKTOP]: {
    },
  },
  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '7',
    border: '2px solid' + COLORS.BORDER,
    borderRadius: '4px',
    width: '100%',
    [BREAKPOINTS.MEDIA_MIN_1024]: {
      width: '108%',
    },
    [BREAKPOINTS.MEDIA_MIN_1101]: {
      width: '108%',
    },
    [BREAKPOINTS.MEDIA_MIN_1367]: {
      width: '110%',
    },
    [BREAKPOINTS.MEDIA_MIN_1400]: {
      width: '966px',
    },
    [BREAKPOINTS.MEDIA_MAX_615]: {
      width: '100%',
      padding: '20px 10px 20px 10px',
    },
    [BREAKPOINTS.MEDIA_MIN_420]: {
      width: '96%',
      padding: '20px 10px 20px 10px',
    },
    [BREAKPOINTS.MEDIA_MIN_375]: {
      width: '95%',
      padding: '20px 10px 20px 10px',
    },
    [BREAKPOINTS.ANDROID]: {
      width: '98.74%',
      padding: '20px 10px 20px 10px',
    },
  },
  image: {
    [BREAKPOINTS.MEDIA_MIN_1024]: {
      width: '300px',
    },
    [BREAKPOINTS.MEDIA_MIN_1101]: {
      width: '310px',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  horizontalButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  iconStyle: {
    color: COLORS.PRIMARY_ACTIONS,
    '&:hover': {
      textDecorationColor: theme.palette.primary.main,
    },
    paddingTop: '3px',
    paddingLeft: '8px',
    fontSize: '16px',
  },
  buttonGroupStyle: {
    display: 'flex',
    alignItems: 'center',
    margin: '28px 0px 0px 0px',
    [BREAKPOINTS.DESKTOP]: {
      margin: '28px 0px 0px 0px',
    },
  },
  QuickstartButtonStyle: {
    borderRadius: '24px',
    padding: '12px 24px 12px 24px',
    width: 'auto',
    height: '48px',
    backgroundColor: COLORS.TEXT_HIGH_EMPHASIS,
    [BREAKPOINTS.MEDIA_MIN_1400]: {
      width: '229px',
    },
    [BREAKPOINTS.MEDIA_MAX_615]: {
      height: '40px',
      width: '135px'
    },
    [BREAKPOINTS.MEDIA_MIN_420]: {
      height: '40px',
      width: '135px'
    },
    [BREAKPOINTS.MEDIA_MIN_375]: {
      height: '38px',
      width: '135px'
    },
    [BREAKPOINTS.ANDROID]: {
      height: '35px',
    },
  },
  quickStartStyle: {
    color: theme.palette.common.white,
  },
  integrateButtonStyle: {
    marginLeft: '10px',
    width: 'auto',
    height: '48px',
    borderRadius: '24px',
    padding: '12px 24px',
    border: '2px solid' + COLORS.TEXT_HIGH_EMPHASIS,
    backgroundColor: 'transparent',
    [BREAKPOINTS.MEDIA_MIN_1400]: {
      width: '229px',
    },
    [BREAKPOINTS.MEDIA_MAX_615]: {
      height: '40px',
      width: '135px'
    },
    [BREAKPOINTS.MEDIA_MIN_420]: {
      height: '40px',
      width: '135px'
    },
    [BREAKPOINTS.MEDIA_MIN_375]: {
      height: '38px',
      width: '135px'
    },
    [BREAKPOINTS.ANDROID]: {
      height: '35px',
    },
  },
  buttonStyle: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
  },
  titleTypography: {
    fontFamily: 'Cosmica',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: COLORS.SECONDARY_MIDNIGHT,
    paddingBottom: '10px',
  },
  noteTypography: {
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    fontSize: '16px',
    lineHeight: '24px',
    paddingTop: '10px',
    paddingLeft: '8px',
  },
};

const InnerBox = styled('div')(({ theme }) => ({
  maxWidth: '989px',
  margin: '74px auto',
  marginBottom: '0px',
  [BREAKPOINTS.MOBILE]: {
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto auto',
    marginTop: theme.spacing(3),
    padding: '20px',
  },
  [BREAKPOINTS.MEDIA_MIN_1101]: {
    margin: '74px 10%',
  },
  [BREAKPOINTS.MEDIA_MIN_1024]: {
    margin: '40px 70px 50px 30px',
  },
}));

const config = {
  issuer: process.env.GATSBY_OKTA_ISSUER_URL || '',
};
const signIn =
  typeof window !== 'undefined' &&
  process.env.GATSBY_OKTA_ISSUER_URL &&
  new OktaAuth(config);
let oktaClient: OktaAuth;
if (signIn) {
  oktaClient = new OktaAuth(config);
}
export const initialSession = async () => {
  if (oktaClient) {
    return oktaClient.session
      .exists()
      .then(async (exists: any) => {
        if (exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err: any) => {
        return false;
      });
  }
  return false;
};
interface Props {
  children?: JSX.Element;
  isHeader?: boolean;
  isPreLogin?: boolean;
  isDefaultLayout?: boolean;
  isPrivateRoute?: boolean;
  title?: string;
  isMobileOpenStudio?: boolean;
}
export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
function getcookie(cookiename) {
  if (typeof document !== 'undefined') {
    const cookiestring = document.cookie;
    const cookiearray = cookiestring.split(';');
    for (const element of cookiearray) {
      if (element.trim().match('^' + cookiename + '=')) {
        const href = element.replace(`${cookiename}=`, '').trim();
        if (href && isValidUrl(href)) {
          const domain = new URL(href).host;
          const result = domain.split('.');
          if (
            (result.length >= 3 && result[1] === globalMessages.domainName) ||
            result[1] === globalMessages.sandoxDomain
          ) {
            return href;
          }
        }
      }
    }
  }
  return routes.preLogin;
}

export const getPrivatePage = (title) => {
  const isMobile = useMediaQuery('(max-width:615px)');
  const buttons = [
    { label: globalMessages.getStarted, route: routes.productGetStarted },
    { label: globalMessages.explore, route: routes.productExplore },
    { label: globalMessages.authenticate, route: routes.apiAuthentication },
    { label: globalMessages.dataApi, route: routes.apiReference.record },
  ];
  return (
    <>
      <SEO title={title} />
      <Box sx={useStyles.outerBox}>
        <InnerBox>
          <Box sx={useStyles.boxstyle}>
            <Paper elevation={12} variant='outlined' square={false} sx={useStyles.contentBox}>
              {!isMobile && <Box component='img' src={LoginImage} alt="image" sx={useStyles.image} />}
              <Box sx={useStyles.rightContent}>
                <Typography sx={useStyles.titleTypography} variant="subtitle1">{globalMessages.title}</Typography>
                <Box sx={useStyles.buttonGroup}>
                  {buttons.map((button, index) => (
                    <LinkWrapper href={button.route}>
                      <Button key={index} disableRipple style={{ backgroundColor: 'transparent' }}>
                        <img src={fileIcon} />
                        <Typography
                          type="SMALL_SUB_HEADER"
                          sx={useStyles.iconStyle}
                        >
                          {button.label}
                        </Typography>
                      </Button>
                    </LinkWrapper>
                  ))}
                </Box>
                <Divider style={{ width: '79%', margin: '16px 0' }} />
                <Typography type="SMALL_SUB_HEADER" sx={useStyles.noteTypography}>{globalMessages.note}</Typography>
                <Box style={useStyles.buttonGroupStyle}>
                  <LinkWrapper href={routes.preLogin} maintainPrevPath={true}>
                    <Button
                      variant="contained"
                      disableRipple
                      sx={useStyles.QuickstartButtonStyle}
                    >
                      <Typography
                        type="DESKTOP_BUTTON_TEXT"
                        sx={useStyles.quickStartStyle}
                      >
                        {globalMessages.signIn}
                      </Typography>
                    </Button>
                  </LinkWrapper>
                  <LinkWrapper href={externalLinks.trySkyflow}>
                    <Button
                      disableRipple
                      variant="outlined"
                      sx={useStyles.integrateButtonStyle}
                    >
                      <Typography
                        type="DESKTOP_BUTTON_TEXT"
                        sx={useStyles.buttonStyle}
                      >
                        {globalMessages.getAccess}
                      </Typography>
                    </Button>
                  </LinkWrapper>
                </Box>
              </Box>
            </Paper>
          </Box>
        </InnerBox>
      </Box>
    </>
  );
};

const SecurityWrapper: React.FC<Props> = ({
  children,
  isHeader,
  isPrivateRoute,
  title,
  isDefaultLayout,
  isPreLogin,
  isMobileOpenStudio,
}) => {
  const [isSessionActive, setIsSessionActive] = React.useState<any>(null);
  const isloading = React.useContext(AppContext).isloading;
  const setIsLoading = React.useContext(AppContext).setIsLoading;
  const [isChanged, setIsChanged] = React.useState(false);
  const history: any = React.useContext(AppContext).history;
  const setHistory = React.useContext(AppContext).setHistory;

  // For pages with .tsx we need client-side authentication. For .md and .json we have server-side authentication.
  // So for .tsx pages we need to include them here to check if login is required.
  const isLoginRequired = title === 'SDKs' || title === 'Demos and sample apps';
  let pathname;
  let hash;

  if(typeof window !== 'undefined')
  {
    pathname = window.location.pathname;
    hash = window.location.hash;
  }

  React.useEffect(() => {
    let temp = '';
    if(history.length>0)
    {
      temp = history[history.length-1].split('/')[1];
    }
    temp = '/' + temp + '/';
    if( temp !== pathname )
    {
      setHistory([...history.slice(-1), pathname + hash]);
    }
  },[pathname]);

  const getSession = async () => {
    if (oktaClient) {
      return oktaClient.session
        .exists()
        .then(async (exists: any) => {
          if (exists) {
            if (isSessionActive !== true) {
              setIsSessionActive(true);
            }
            return true;
          } else {
            if (isSessionActive !== false) {
              setIsSessionActive(false);
            }
            return false;
          }
        })
        .catch((err: any) => {
          return false;
        });
    }
    return false;
  };
  const displayPreLoginBasedOnSession = (isSessionActive) => {
    return isSessionActive ? navigate(routes.home) : <>{children}</>;
  };
  const handleOpenStudio = () => {
    const checkSession = async () => {
      const intialValue = await initialSession();
      setIsSessionActive(intialValue);
      if(intialValue) {
        const url = getcookie(globalMessages.applicationUrl);
        url === routes.preLogin ? navigate(url) : window.open(url);
      } else {
        navigate(routes.preLogin);
      }
    };
    checkSession();
  };
  const showOpenStudio = () => {
    return (
        <Button
          sx={
            isMobileOpenStudio
              ? useStyles.mobileOpenStudioStyle
              : useStyles.openStudioStyle
          }
          disableRipple
          onClick={handleOpenStudio}
        >
          <img src={ExternalLinkSvg} style={useStyles.icon} alt="arrow_right" />
          <Typography type="SMALL_SUB_HEADER">
            {globalMessages.openStudio}
          </Typography>
        </Button>
    );
  };

  React.useEffect(() => {
    setIsLoading(false);
  },[children]);
  React.useEffect(()=> {
    if(isChanged)
    {
      typeof window !== 'undefined' && window.location.reload();
    }
  },[isChanged]);

  React.useEffect(() => {
    if (isPrivateRoute && isLoginRequired && isSessionActive === null) {
      const checkSession = async () => {
        const intialValue = await initialSession();
        setIsSessionActive(intialValue);
      };
      checkSession();
    }
  }, [isPrivateRoute, isLoginRequired]);

  function listenCookieChange(callback, interval = 5000) {
    if (typeof document !== 'undefined') {
      let lastCookie = document.cookie;
      setInterval(() => {
        const cookie = document.cookie;
        if (cookie !== lastCookie) {
          try {
            callback({ oldValue: lastCookie, newValue: cookie });
          } finally {
            lastCookie = cookie;
          }
        }
      }, interval);
    }
  }
  listenCookieChange(({ oldValue, newValue }) => {
    const latestCookies = newValue.split(';');
    const oldCookies = oldValue.split(';');
    let previousAppURL = '';
    let latestAppURL = '';
    for (const element of latestCookies) {
      if (element.trim().match('^' + globalMessages.applicationUrl + '=')) {
        latestAppURL = element
          .replace(`${globalMessages.applicationUrl}=`, '')
          .trim();
      }
    }
    for (const element of oldCookies) {
      if (element.trim().match('^' + globalMessages.applicationUrl + '=')) {
        previousAppURL = element
          .replace(`${globalMessages.applicationUrl}=`, '')
          .trim();
      }
    }
    if (previousAppURL !== latestAppURL) {
      setIsChanged(true);
    }
  }, 5000);

  const displayBasedOnSession = (isSessionActive) => {
    return isSessionActive === true ? <>{children}</> : getPrivatePage(title);
  };

  return (
    <>
      {isDefaultLayout && isPrivateRoute === false && (isloading === true ? 
          <LoadingState /> : <>{children}</>)}
      {isDefaultLayout &&
        isPrivateRoute === true &&
        (isloading === true || (isLoginRequired && isSessionActive === null) ? 
          <LoadingState />
         : 
         isLoginRequired ?
          displayBasedOnSession(isSessionActive)
          :
          <>{children}</>
        )}
      {isPreLogin &&
        (isloading === true ? (
          <LoadingState />
        ) : (
          displayPreLoginBasedOnSession(isSessionActive)
        ))}
      {isHeader && showOpenStudio()}
    </>
  );
};
export default React.memo(SecurityWrapper);
