import React, { useContext, useState } from 'react';
import { Tab as TabComponent, Tabs as TabWrapper } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import theme, { BREAKPOINTS, COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { StoreContext } from '../SyncTabs';

const useStyles = {
  tabsStyle: {
    minHeight: 'unset',
    '& .MuiTabs-flexContainer': {
      borderBottom: 2,
      borderColor: theme.palette.grey[300],
    },
  },
  tabPanelWrapper: { padding: theme.spacing(5, 0, 0) },
  tabsContainer: {
    width: '100%',
    borderRadius: theme.spacing(1),
    boxShadow: 'none',
    marginBottom: theme.spacing(15),
    [BREAKPOINTS.MOBILE]: {
      marginBottom: theme.spacing(8),
    },
  },
  tabHeaderStyle: {
    padding: '0px 12px 12px',
    minHeight: 'unset',
    '&.Mui-selected': {
      fontWeight: 500,
    },
  },
  tabsWrapper: { padding: theme.spacing(5, 0, 0, 0) },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={useStyles.tabPanelWrapper}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const addTabOnChangeEventToDataLayer = (label) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'tabOnChangeSubmission',
      tabLabel: label,
    });
  }
  return '';
};
const checkIfRequestedTab = (label) => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    const getQueryParams = url.split('?')[1];
    if (getQueryParams) {
      const labelAfterTrim = label.replace(/ /g, '_').toLowerCase();
      if (getQueryParams.includes(labelAfterTrim)) {
        return true;
      }
    }
  }
  return false;
};
const Tabs = ({ children }) => {

  const [tabValue, setTabValue] = React.useState(0);
  
  const tabs = useContext(StoreContext);
  const label = tabs.defaultLabel;
  let value = tabs.value;

  React.useEffect(() => {
    for (let i = 0; i < children.length; i++) {
      if (
        children[i].props.label &&
        checkIfRequestedTab(children[i].props.label)
      ) {
        tabs.setValue(i);
      }
    }
  }, []);
  if(label && ((children.length > tabs.value && children[tabs.value].props.label !== label) || children.length === tabs.value))
  {
    if(tabs.preValue > 0)
    {
      value = tabs.preValue;
    }
    else{
      value = 0;
    }
  }
  const flags = useFlags();
  const handleChange = (event, newValue) => {
    if(tabs.value === -1)
    {
      setTabValue(newValue);
    }
    else {
      tabs.setPreValue(value);
      tabs.setValue(newValue);
      tabs.setLabel(children[newValue].props.label);
    }
    if (
      children[newValue] &&
      children[newValue].props.label &&
      flags.enableDocAnalyticsEvents === true
    ) {
      addTabOnChangeEventToDataLayer(children[newValue].props.label);
    }
  };
  return (
    <Box sx={useStyles.tabsContainer}>
      <Box sx={useStyles.tabsWrapper}>
        <TabWrapper
          onChange={handleChange}
          value={tabs.value === -1 ? tabValue : value}
          sx={useStyles.tabsStyle}
        >
          {children.map((child, idx) => 
            <TabComponent
              key={idx}
              label={
                <Typography
                  type={
                    value === idx
                      ? 'DESKTOP_ACTION_SECONDARY_MEDIUM'
                      : 'DESKTOP_ACTION_SECONDARY'
                  }
                  component="span"
                >
                  {child.props.label}
                </Typography>
              }
              {...a11yProps(idx)}
              sx={useStyles.tabHeaderStyle}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            />
          )}
        </TabWrapper>
        {children.map((child, idx) => 
          <TabPanel key={idx} value={tabs.value === -1 ? tabValue : value} index={idx}>
            {child.props.children}
          </TabPanel>
        )}
      </Box>
    </Box>
  );
};
export const Tab = ({ children, label }) => null;

export default Tabs;
