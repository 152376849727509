const homePageMessages = {
  guideTitle: 'Start Building',
  guideSubTitle: 'Choose one of the quickstart guides below to get started',
  routeTitle: 'Demos & Samples',
  routeSubTitle: 'See how Skyflow can be used to achieve your use case',
  quickStart: 'Get started',
  apiDocs: 'API docs',
  welcome: 'Welcome to Docs',
  description:
    "Here you'll find guides, demos and samples to help you build with Skyflow.",
  explore: 'Explore what Skyflow can do',
  fullAnswer: 'See more',
  carouselHeading: 'Q&A',
  announcement: '',
  usecase: 'By use case',
  byProduct: 'By product',
  mostVisited:'Most visited',
};

export default homePageMessages;
