import globalMessages from "../../utils/messages/en/globalMessages";
declare global {
  interface Window {
    CoralogixRum: any;
    hasSentNavigatedFromLog: boolean;
    hasSentReferrerLog: boolean;
  }
}

if (typeof window !== 'undefined') {
  window.hasSentNavigatedFromLog = false;
  window.hasSentReferrerLog = false;
}

const useCoralogix = (pathname, previousLocation) => {
  if (typeof document !== 'undefined') {
    const referrer = document.referrer;
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://cdn.rum-ingress-coralogix.com/coralogix/browser/latest/coralogix-browser-sdk.js';
    document.head.append(scriptTag);
    scriptTag.onload = () => {
      if (!window.CoralogixRum.isInited) {
        window.CoralogixRum.init({
          application: globalMessages.application,
          public_key: process.env.GATSBY_CORALOGIX_PUBLIC_KEY,
          coralogixDomain: globalMessages.coralogixDomain,
          version: globalMessages.version,
          beforeSend: (event) => {
            if (event.internal_context?.event === 'init') {
              return event;
            }
            if (event.internal_context?.event !== 'init') {
              if (event.labels?.message === 'navigatedFrom') {
                if (window.hasSentNavigatedFromLog) {
                  return null;
                } else {
                  window.hasSentNavigatedFromLog = true;
                  return event;
                }
              } else if (event.labels?.referrer != null && event.labels?.referrer?.trim() !== '') {
                if (window.hasSentReferrerLog) {
                  return null;
                } else {
                  window.hasSentReferrerLog = true;
                  return event;
                }
              } else {
                return null;
              }
            }
            return null;
          }
        });
      }
      if (pathname === '/pre-login/' && previousLocation) {
        window.CoralogixRum.setLabels({
          message: `navigatedFrom`,
          navigatedFrom: `${previousLocation}`,
        });
      } else {
        window.CoralogixRum.setLabels({
          referrer: `${referrer}`,
        });
      };
    };
    return () => {
    };
  }
};

export default useCoralogix;
