import React from 'react';
import { pageType } from './index';
import { isMobileView, updateHistoryState } from '../../../utils/helper';
import { useFlags } from 'gatsby-plugin-launchdarkly';

const expandLabel = (sideNavItems, index, setopen, open) => {
  if (open[index] === false) {
    const newArr = Array(sideNavItems.length).fill(false);
    newArr[index] = !open[index];
    setopen(newArr);
  }
};
const RemoveTrailingSlash = (element) => {
  const url = element;
  if (url && url.length >= 1 && url.charAt(url.length - 1) === '/') {
    return url.substr(0, url.length - 1);
  }
  return url;
};
const appendRouteToUrl = (routeToBeAppended) => {
  let selectionItem = '';
  selectionItem = routeToBeAppended.includes('#')
    ? routeToBeAppended
    : '#' + routeToBeAppended;
  if (selectionItem && selectionItem.includes('#')) {
    const search = '#';
    const replaceWith = '';
    const result = selectionItem.split(search).join(replaceWith);
    const ele = document.getElementById(result);
    if (ele) {
      if (/iPhone/.test(navigator.userAgent)) {
        ele.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      } else {
        ele.scrollIntoView();
      }
      window.history.pushState(
        null,
        '',
        window.location.href.split('#')[0] + selectionItem,
      );
    }
  }
};
const onScroll = () => {
  let current = '';
  const sections = document.querySelectorAll('h2');
  let lastSection = '';
  sections.forEach((section) => {
    const { offsetTop, clientHeight } = section;
    const temp = section.getAttribute('id');
    if (scrollY >= offsetTop - clientHeight - 60) {
      current = temp !== null ? temp : '';
    }
    lastSection = temp !== null ? temp : '';
  });
  const scrollable = document.documentElement.scrollHeight - window.innerHeight;
  const scrolled = Math.round(window.scrollY);
  if (scrolled === scrollable) {
    return lastSection;
  } else {
    return current;
  }
};
const onScrollGetActiveParent = () => {
  const parentSections = document.querySelectorAll('h1');
  let currentParent = '';
  let lastSection = '';
  parentSections.forEach((section) => {
    const { offsetTop, clientHeight } = section;
    const temp = section.getAttribute('id');
    if (scrollY >= offsetTop - clientHeight - 70) {
      currentParent = temp !== null ? temp : '';
    }
    lastSection = temp !== null ? temp : '';
  });
  const scrollable = document.documentElement.scrollHeight - window.innerHeight;
  const scrolled = Math.round(window.scrollY);
  if (scrolled === scrollable) {
    return lastSection;
  } else {
    return currentParent;
  }
};
const checkIfNeightbhourParentLabel = (
  sideNavItems,
  currentChild,
  currentParent,
  i,
  setParentActiveItem,
) => {
  if (sideNavItems[i].child) {
    for (const childElement of sideNavItems[i].child) {
      if ('#' + currentChild === childElement.route) {
        if (
          sideNavItems[i + 1] &&
          sideNavItems[i + 1].route === '#' + currentParent
        ) {
          return false;
        } else {
          setParentActiveItem(sideNavItems[i].route);
          return true;
        }
      }
    }
  }
};
const setParentBasedOnChildLabel = (
  sideNavItems,
  currentChild,
  currentParent,
  setParentActiveItem,
) => {
  for (let i = 0; i < sideNavItems.length; i++) {
    if (
      checkIfNeightbhourParentLabel(
        sideNavItems,
        currentChild,
        currentParent,
        i,
        setParentActiveItem,
      ) === true
    ) {
      return true;
    }
  }
  return false;
};
const expandParentLabel = (
  sideNavItems,
  parentActiveItem,
  expandLabel,
  setopen,
  open,
) => {
  for (let index = 0; index < sideNavItems.length; index++) {
    if (
      sideNavItems[index].route &&
      sideNavItems[index].route === parentActiveItem
    ) {
      expandLabel(sideNavItems, index, setopen, open);
      break;
    }
  }
};
export const useNavBar = (
  sideNavItems,
  type,
  pathName,
  level,
  activeItem,
  setActiveItem,
  parentActiveItem,
  setParentActiveItem,
) => {
  const apiPage = pageType.api;
  const flags = useFlags();
 
  const getStateOfSidenavItems=(item)=>{
    if(item.route===pathName){
      return true
    }
    else {
      if(item.child){
        for(let i of item.child){
          if(getStateOfSidenavItems(i)) return true
        }
        return false
      }
      else return false
    }
  }
  
  const items=sideNavItems.map((item)=>{
    return getStateOfSidenavItems(item)
  })
  const [open, setopen] = React.useState<any>(
    items
  );
  if (sideNavItems && open && sideNavItems.length !== open.length) {
    setopen(items);
  }
  React.useEffect(() => {
    if (type === pageType[apiPage]) {
      expandParentLabel(
        sideNavItems,
        parentActiveItem,
        expandLabel,
        setopen,
        open,
      );
      if (level === 2) {
        sideNavItems.forEach((element, index) => {
          if (element.route === '#' + activeItem) {
            expandLabel(sideNavItems, index, setopen, open);
          }
        });
      }
    }
  }, [activeItem]);
  React.useEffect(() => {
    if (type === pageType[apiPage]) {
      expandParentLabel(
        sideNavItems,
        parentActiveItem,
        expandLabel,
        setopen,
        open,
      );
    }
  }, [parentActiveItem]);

  const checkIfGrandChildGotSelected = (
    childItem,
    childIndex,
    selectedRoute,
    index,
  ) => {
    if (childItem.child) {
      childItem.child.some(function (grandChildItem, grandChildIndex) {
        const garndChildRoute = RemoveTrailingSlash(grandChildItem.route);
        if (garndChildRoute === selectedRoute) {
          level === 1
            ? expandLabel(sideNavItems, index, setopen, open)
            : expandLabel(sideNavItems, childIndex, setopen, open);
          return true;
        }
        return false;
      });
    }
  };
  React.useEffect(() => {
    const selectedRoute = RemoveTrailingSlash(window.location.pathname);
    sideNavItems.some(function (parentItem, index) {
      const parentRoute = RemoveTrailingSlash(parentItem.route);
      if (parentRoute === selectedRoute) {
        expandLabel(sideNavItems, index, setopen, open);
        return true;
      } else if (level <= 2 && parentItem.child) {
        parentItem.child.some(function (childItem, childIndex) {
          const childRoute = RemoveTrailingSlash(childItem.route);
          if (childRoute === selectedRoute) {
            expandLabel(sideNavItems, index, setopen, open);
            return true;
          }
          checkIfGrandChildGotSelected(
            childItem,
            childIndex,
            selectedRoute,
            index,
          );
          return false;
        });
      }
      return false;
    });
  }, [pathName]);

  React.useEffect(() => {
    let isMounted = true;
    if (
      typeof window !== 'undefined' &&
      type === pageType[apiPage] &&
      isMounted
    ) {
      window.addEventListener('scroll', () => {
        const currentChild = onScroll();
        const currentParent = onScrollGetActiveParent();
        let flag = false;
        if(currentChild !== '') {
          updateHistoryState(currentChild);
        }
        setActiveItem(currentChild);
        flag = setParentBasedOnChildLabel(
          sideNavItems,
          currentChild,
          currentParent,
          setParentActiveItem,
        );
        if (flag === false) {
          setParentActiveItem('#' + currentParent);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [type]);
  const addLeftNavClickEventToDataLayer = (label) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'leftNavClickSubmission',
        linkId: label,
      });
    }
    return '';
  };
  const handleClick = (index, child, route, type, setDrawer, label) => {
    if (label && flags.enableDocAnalyticsEvents === true) {
      addLeftNavClickEventToDataLayer(label);
    }
    if (route && type === pageType[apiPage] && open[index] !== true) {
      appendRouteToUrl(route);
    }
    const newArr = Array(sideNavItems.length).fill(false);
    if (!open[index] || child) {
      newArr[index] = !open[index];
      setopen(newArr);
    }
    if (isMobileView() && route) {
      if (type !== pageType[apiPage]) {
        setDrawer(false);
      } else if (type === pageType[apiPage] && level === 1 && child) {
        return;
      } else {
        setDrawer(false);
      }
    }
  };
  return { handleClick, open };
};
