import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { BREAKPOINTS } from '../../../utils/theme';
import CardLayout, { CardLayoutProps } from '../CardLayout';

const useStyles = {
  cardGroupStyle: {
    position: 'relative',
    display: 'grid',
    gridGap: '16px',
    flex: '1',
    flexDirection: 'column',
    [BREAKPOINTS.MOBILE]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(228.5px,1fr))',
    },
    [BREAKPOINTS.DESKTOP]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(228.5px,1fr))',
    },
  },
  cardItemStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
};
export interface CardLayoutGroupProps {
  cardData: CardLayoutProps[];
  maxCardsPerRow?: number;
}
const CardLayoutGroup: React.FC<CardLayoutGroupProps> = ({
  cardData,
  maxCardsPerRow
}) => {
  return (
    <Grid container sx={{
      ...useStyles.cardGroupStyle,
      gridTemplateColumns: maxCardsPerRow ?
        maxCardsPerRow > 3 ? `repeat(${maxCardsPerRow}, minmax(165.5px, 1fr))` : `repeat(${maxCardsPerRow}, minmax(228.5px, 1fr))`
        : `repeat(auto-fit, minmax(228.5px, 1fr))`
    }}>
      {cardData.map((card) =>
        <Grid item key={card.title} sx={useStyles.cardItemStyle}>
          <CardLayout {...card} />
        </Grid>
      )}
      <Typography></Typography>
      <div></div>
    </Grid>
  );
};

export default CardLayoutGroup;
