import { Box } from '@mui/system';
import React from 'react';
import { styled } from '@mui/material/styles';
import theme from '../../../utils/theme';

const useStyles = {
  parent: {
    display: 'flex',
    margin: '104px 80px',
  },
  leftContainer: {
    margin: '10px',
    minWidth: '70%',
  },
  rightContainer: {
    minWidth: '30%',
  },
  titleStyle: {
    width: '70%',
    height: '20px',
  },
  paragraphStyle: {
    marginTop: '40px',
  },
  contentStyle: {
    display: 'flex',
  },
  paragraph: {
    width: '90%',
    height: '15px',
    marginBottom: '10px',
  },
  description: {
    width: '80%',
    height: '20px',
    marginTop: '10px',
  },
  animate: {
    background: '#f6f7f8', //As these colors are not available in design library , have specified color-code
    backgroundImage:
      'linear-gradient(to right, #f6f7f8 0%,  #e6e6e6 20%, #f6f7f8 40%, #f6f7f8 100%)', //As these colors are not available in design library , have specified color-code
    backgroundRepeat: 'no-repeat',
    backgroundSize: '800px 104px',
    display: 'inline-block',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: 'placeholderShimmer',
    animationTimingFunction: 'linear',
  },
  shimmer: {
    padding: '5px',
    height: '120px',
    background: theme.palette.common.white,
  },
};

const StyledAnimation = styled('div')({
  '@keyframes fullView': {
    '100%': {
      width: '100%',
    },
  },
  '@-webkit-keyframes placeholderShimmer': {
    '0%': {
      backgroundPosition: '-468px 0',
    },
    '100%': {
      backgroundPosition: '468px 0',
    },
  },
});
const LoadingState = () => {
  const rightBarCount = 8;
  const leftBarCount = 4;
  return (
    <Box sx={useStyles.parent}>
      <Box sx={useStyles.leftContainer}>
        <StyledAnimation>
          <Box sx={useStyles.shimmer}>
            <Box sx={{ ...useStyles.animate, ...useStyles.titleStyle }}></Box>
            <Box sx={{ ...useStyles.animate, ...useStyles.description }}></Box>
            <Box sx={useStyles.paragraphStyle}>
              <Box>
                {[...Array(leftBarCount)].map((elementInArray, index) => 
                  <Box
                    key={index}
                    sx={{ ...useStyles.animate, ...useStyles.paragraph }}
                  ></Box>
                )}
              </Box>
            </Box>
          </Box>
        </StyledAnimation>
      </Box>
      <Box sx={useStyles.rightContainer}>
        <StyledAnimation>
          <>
            {[...Array(rightBarCount)].map((elementInArray, index) => 
              <Box
                key={index}
                sx={{ ...useStyles.animate, ...useStyles.paragraph }}
              ></Box>
            )}
          </>
        </StyledAnimation>
      </Box>
    </Box>
  );
};

export default LoadingState;
