import React from 'react';
import {
  Typography as TypographyComponent,
  TypographyProps,
} from '@mui/material';
import { useGlobalStyles } from '../../../utils/theme';

interface TypograhyComponentProps extends TypographyProps {
  type?:
    | 'DESKTOP_HEADING_ALLCAPS'
    | 'DESKTOP_BODY_PARAGRAPH'
    | 'DESKTOP_BODY_PARAGRAPH_MEDIUM'
    | 'DESKTOP_BODY_SMALL_TEXT'
    | 'DESKTOP_BODY_LINKS'
    | 'DESKTOP_ACTION_PRIMARY'
    | 'DESKTOP_ACTION_PRIMARY_BOLD'
    | 'DESKTOP_ACTION_SECONDARY'
    | 'DESKTOP_ACTION_SECONDARY_MEDIUM'
    | 'DESKTOP_CODE_PRIMARY'
    | 'DESKTOP_CODE_SECONDARY'
    | 'DESKTOP_CODE_ALLCAPS'
    | 'SMALL_LIST_HEADER'
    | 'REGULAR_TABLE_CELL'
    | 'SMALL_SUB_HEADER'
    | 'MEDIUM_SUB_HEADER'
    | 'INPUT_LABEL'
    | 'DESKTOP_BUTTON_TEXT'
    | 'DESKTOP_BODY_TEXT'
    | 'DESKTOP_BODY_SUBTITLE'
    | 'DESKTOP_HEADING'
    | 'DESKTOP_TITLE'
    | 'DESKTOP_BUTTON'
    | 'DESKTOP_BODY_HEADING'
    | 'DESKTOP_BODY_TITLE'
    | 'DESKTOP_SUBTITLE'
    | 'DESKTOP_TITLE_HEADING'
    | 'ANNOUNCEMENT_TITLE';
  component?: string;
}

const Typography: React.FC<TypograhyComponentProps> = (
  props: TypograhyComponentProps,
) => {
  const { type } = props;
  let style = { ...props.sx };
  if (type) {
    style = {
      ...useGlobalStyles[type],
      ...style,
    };
  }
  return (
    <TypographyComponent {...props} sx={style}>
      {props.children}
    </TypographyComponent>
  );
};

export default Typography;
