import React from 'react';
import theme, { COLORS } from '../../../utils/theme';
import { Box } from '@mui/system';
import Typography from '../../common/Typography';

const useStyles = {
  methodStyle: {
    borderRadius: theme.spacing(1),
    width: '40px',
    height: '17px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textStyle: {
    paddingTop: '2px',
  },
};

const TagType = ({ method }) => {
  const getStyle = (method) => {
    let style = {};
    switch (method.toLowerCase()) {
      case 'get':
        style = {
          backgroundColor: COLORS.PRIMARY_FRONT,
        };
        break;
      case 'put':
        style = {
          backgroundColor: COLORS.PRIMARY_DAWN,
        };
        break;
      case 'post':
        style = {
          backgroundColor: COLORS.SECONDARY_DUSK,
        };
        break;
      case 'delete':
        style = {
          backgroundColor: COLORS.PRIMARY_DAY,
        };
        break;
      case 'patch':
        style = {
          backgroundColor: COLORS.PRIMARY_AIR,
        };
        break;
      case 'new':
        style = {
          backgroundColor: COLORS.PRIMARY_FRONT,
          color: COLORS.SECONDARY_MIDNIGHT,
          padding: '0px 6.5px',
        };
        break;
      case 'fix':
        style = {
          backgroundColor: COLORS.PRIMARY_DAY,
          color: COLORS.PRIMARY_WHITE,
          padding: '0px 6.5px',
        };
        break;
      case 'improved':
        style = {
          backgroundColor: COLORS.SECONDARY_DUSK,
          color: COLORS.SECONDARY_MIDNIGHT,
          width: '100%',
          padding: '0px 6.5px',
        };
        break;
      case 'beta':
        style = {
          backgroundColor: COLORS.PRIMARY_DAWN,
          color: COLORS.SECONDARY_MIDNIGHT,
          width: '100%',
          padding: '0px 6.5px',
        };
        break;
      default:
        style = {
          backgroundColor: COLORS.TEXT_LOW_EMPHASIS,
          color: COLORS.SECONDARY_MIDNIGHT,
          width: '100%',
          padding: '0px 6.5px',
        };
        break;
    }
    return { ...useStyles.methodStyle, ...style };
  };
  const getTextStyle = (method) => {
    let style = {};
    switch (method.toLowerCase()) {
      case 'get':
      case 'put':
      case 'patch':
        style = {
          color: COLORS.SECONDARY_NIGHT,
        };
        break;
      case 'post':
      case 'delete':
      case 'fix':
        style = {
          color: COLORS.PRIMARY_WHITE,
        };
        break;
      default:
        style = {
          color: COLORS.SECONDARY_MIDNIGHT,
        };
        break;
    }
    return {
      ...useStyles.textStyle,
      ...style,
    } as const;
  };
  const getMethodString = (method) => {
    if (method.toLowerCase() === 'delete') {
      return method.substring(0, 3).toUpperCase();
    } else {
      return method.toUpperCase();
    }
  };
  return (
    <Box sx={getStyle(method)}>
      <Typography type="DESKTOP_CODE_ALLCAPS" sx={getTextStyle(method)}>
        {getMethodString(method)}
      </Typography>
    </Box>
  );
};

export default TagType;
