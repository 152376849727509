/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavMenu from '../common/NavMenu';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Box, styled } from '@mui/system';
import ThemeWrapper from '../../utils/wrapper/ThemeWrapper/index';
import { Grid, IconButton } from '@mui/material';
import MDXWrapper from '../docs/MDXWrapper';
import HeaderLogo from '../common/HeaderLogo';
import { isMobile } from 'react-device-detect';
import theme, { BREAKPOINTS, COLORS, MOBILE_SPACING } from '../../utils/theme';
import SearchField from '../common/SearchField';
import MoonIcon from '../../../static/images/moon.svg';
import searchIcon from '../../../static/images/search.svg';
import { navigate } from 'gatsby';
import { HOME_ROUTE, MOBILE_HOME_ROUTE } from '../../utils/Route';
import LoadingState from '../common/LoadingState';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { isMobileView } from '../../utils/helper';
import SecurityWrapper from '../common/SecurityWrapper';
import routes from '../../docs-config/src';
import SEO from '../common/Seo';
import AppContextProvider, { AppContext } from '../AppContextProvider';
import useCoralogix from './hook';

const styles = {
  outer_box: {
    display: 'flex',
  },
  headerBox: {
    position: 'fixed',
    top: '0',
    zIndex: '100',
    width: 'calc(100% - 252px)',
    maxWidth: '1178px',
    paddingLeft: '0px'
  },
  logoExpand: {
    position: 'fixed',
    maxWidth: '270px',
    zIndex: '100',
    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
      padding: '24px 20px 20px 20px',
      justifyContent: 'space-between',
      width: '305px',
    },
  },
  centerBoxExpanded: {
    minHeight: '100vh',
    position: 'relative',
    [BREAKPOINTS.DEFAULT]: {
      minHeight: '100%',
    },
  },
  logoCollapsed: {
    position: 'fixed',
    maxWidth: '270px',
    zIndex: '100',
  },
  footerBox: {
    bottom: '0',
    left: '0',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
  },
  logoStyle: {
    maxHeight: '60px',
    [BREAKPOINTS.MOBILE]: {
      position: 'sticky',
      top: '0',
      zIndex: '100',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  header_box: {
    position: 'sticky',
    top: '0',
    zIndex: '100',
    display: 'flex',
    width: '100%',
    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      width: `calc(100% - ${MOBILE_SPACING.MARGIN})`,
      padding: '0px 20px',
      paddingBottom: '0px',
      backgroundColor: COLORS.BACKGROUND_PRIMARY,
    },
  },
  headerWrapper: {
    display: 'Flex',
    width: '100%',
    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  headerStyle: {
    maxHeight: '60px',
  },
  headerLogoStyle: {
    maxHeight: '60px',
    width: 'calc(100% - 270px)',
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
      width: '100%',
      maxHeight: '18px',
    },
  },
  contentStyle: {
    paddingTop: '60px',
    position: 'relative',
  },
  mobileSearchStyle: {
    display: 'flex',
    paddingTop: '24px',
    paddingBottom: '20px',
  },
  iconButton: {
    padding: '0px',
    display: 'none',
  },
  moonIcon: {
    width: '18px',
    height: '18px',
  },
  searchIconStyle: {
    width: '16px',
    height: '16px',
  },
  searchStyling: {
    display: 'none',
    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      justifyContent: 'flex-end',
    },
  },
  displayStyle: {
    display: 'none',
  },
  searchOpenStyle: {
    display: 'flex',
    width: '100%',
    paddingTop: '24px',
    paddingBottom: '20px',
  },
  searchBoxStyle: {
    width: '100%',
  },
  center: {
    minHeight: '100vh',
    position: 'relative',
    borderLeft: '1px solid' + theme.palette.grey[300],
    [BREAKPOINTS.MD]: {
      marginLeft: '271px !important',
    },
  },
};
const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1440px',
  },
  position: 'relative',
  margin: 'auto',
}));
const CenterBox = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  borderLeft: '1px solid' + theme.palette.grey[300],
  [theme.breakpoints.up('md')]: {
    marginLeft: '271px !important',
  },
}));
const Content = styled('div')(({ theme }) => ({
  minHeight: 'calc(100vh - 157px)',
  marginBottom: '44px',
  overflow: 'auto',
  [BREAKPOINTS.CUSTOM]: {
    minHeight: 'calc(100vh - 186px)',
  },
}));
const SideBar = styled('div')(({ theme }) => ({
  height: '100vh',
  position: 'fixed',
  top: '60px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '270px',
  },
}));

let title;
const removeTrailingslash = (url) => {
  if (url && url.charAt(url.length - 1) === '/') {
    return url.substr(0, url.length - 1);
  }
  return url;
};
const setTitle = (element) => {
  if (element.public) {
    return true;
  }
  title = element.label;
  return false;
};
const isPublicRouteInArticlePages = (currentRoute, sideNavItems) => {
  if (sideNavItems) {
    const res = sideNavItems.filter((element) =>
      removeTrailingslash(element.route) ===
        removeTrailingslash(currentRoute) ||
      (currentRoute?.includes('/content/docs') &&
        currentRoute?.includes(element.route))
        ? setTitle(element)
        : isPublicRouteInArticlePages(currentRoute, element.child),
    );
    return res.length > 0 ? true : false;
  }
  return false;
};


function getSideNavItems(type: string, serverData: any): any[] {
  if (type === 'api') {
    return serverData.sideNavList;
  } else if (type === 'sdk-ref') {
    return serverData.sdkSideNavList;
  } else if (type === 'docs') {
    return serverData.docsSideNavList;
  } else {
    return serverData?.clientSideNavList || [];
  }
}

const Layout: React.FC<any> = (props: any) => {
  const { baseSettings } = props?.serverData || {};
  const type = props.serverData?.type;
  const pathname = props.location.pathname;
  const isMobileChecker = !isMobileView();
  const [open, setOpen] = useState(true);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const previousLocation: any = React.useContext(AppContext).previousLocation;
  let selected: 'api' | 'docs' | 'apireference' | 'sdk-ref' = 'docs';
  if (type === 'api') {
    selected = 'api';
  }
  else if(type === 'sdk-ref') {
    selected = 'sdk-ref';
  }
  const flags = useFlags();
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeItem, setActiveItem] = React.useState<any>('');
  const [parentActiveItem, setParentActiveItem] = React.useState<any>('');
  const sideNavItems = getSideNavItems(type, props.serverData);
  const checkisPublicRoute = (type, pathName) => {
    if (type !== 'api') {
      return isPublicRouteInArticlePages(pathName, sideNavItems);
    }
    return false;
  };

  React.useEffect(() => {
    setOpen(isMobileChecker);
  }, [isMobileChecker]);
  React.useEffect(() => {
    setIsLoading(false);
    function handleResize() {
      setOpen(!isMobileView());
      if (!isMobileView() && window.innerWidth >= 1024) {
        setSearchOpen(false);
      }
    }
    window.addEventListener('resize', handleResize);
  }, []);
  const [pageType, setPageType] = useState<'api' | 'docs' | 'apireference' | 'sdk-ref'>(
    selected,
  );
  React.useEffect(() => {
    if (type) {
      setPageType(type);
    } else {
      setPageType('apireference');
    }
  }, [type]);
  React.useEffect(() => {
    setSearchOpen(false);
  }, [props.location.pathname]);
  const handleClick = () => {
    setSearchOpen(!isSearchOpen);
  };
  const isCustomLayout = (pathname) => {
    if (pathname === routes.preLogin) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (pathname !== '/pre-login/') {
      previousLocation.length = 0;
      previousLocation.push(pathname);
    }
  }, [pathname]);
  React.useEffect(() => {
    useCoralogix(pathname, previousLocation[0]);
  }, [pathname, previousLocation]);
  const customHeader = () => {
    return (
      <Box>
        {!isSearchOpen ? (
          <Box sx={styles.logoStyle}>
            <HeaderLogo
              type={pageType}
              open={open}
              setOpen={setOpen}
              setType={setPageType}
              sideNavItems={sideNavItems}
              level={1}
              pathName={props.location.pathname}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              parentActiveItem={parentActiveItem}
              setParentActiveItem={setParentActiveItem}
            />
            {isMobileView() && (
              <Box sx={styles.searchStyling}>
                <IconButton
                  disableRipple
                  sx={{ padding: '0px' }}
                  aria-label="search icon"
                  onClick={handleClick}
                >
                  <img
                    src={searchIcon}
                    style={styles.searchIconStyle}
                    alt="dark_mode"
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={styles.searchOpenStyle}>
            <Box sx={styles.searchBoxStyle}>
              <SearchField
                pageType={pageType}
                handleClick={handleClick}
                isSearchOpen={isSearchOpen}
                baseSettings={baseSettings} />
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  function SelectedHeader(isLoading: boolean) {
    return (
      <Box
        sx={
          !isLoading
            ? styles.header_box
            : { ...styles.header_box, ...styles.displayStyle }
        }
      >
        <Box sx={styles.headerWrapper}>
          <Box>{customHeader()}</Box>
          <Box sx={styles.headerLogoStyle}>
            <Header selected={pageType} setSelected={setPageType} open={open} baseSettings = {baseSettings} />
          </Box>
        </Box>
      </Box>
    );
  }
  // To be Removed once mobile site is ready
  const apiRouteLookup = {
    '/apireference/': 'developer-portal/apireference/',
  };
  const shouldShowOldApi = () => {
    return flags.enableApiReference && apiRouteLookup[pathname];
  };
  if (isMobile && flags.enableMobileView) {
    const routeLookup = {
      '/product-overview/':
        'developer-portal/getting-started/product-overview/',
      '/core-apis/': 'developer-portal/getting-started/core-api-quickstart/',
      '/apireference/': 'developer-portal/apireference/',
    };
    navigate(MOBILE_HOME_ROUTE + (routeLookup[pathname] || ''));
  }
  if (isMobile && shouldShowOldApi()) {
    navigate(MOBILE_HOME_ROUTE + (apiRouteLookup[pathname] || ''));
  }
  const isNewDocSiteEnabled = () => {
    return (
      (!isMobile || flags.enableMobileView === false) &&
      (!isMobile || (flags.enableMobileView === false && !shouldShowOldApi()))
    );
  };
  const disabledLayoutPaths = ['blob-doc'];
  const isLayoutDisabled = disabledLayoutPaths.some(path => pathname.includes(path));
  if (isLayoutDisabled) {
    return (
      <Box sx={isLoading ? styles.displayStyle : {}}>
        {props.children}
      </Box>
    );
  }
  return (
    <ThemeWrapper>
      <MDXWrapper>
        <AppContextProvider>
          {!isCustomLayout(pathname) && (
            <Box>
              {isNewDocSiteEnabled() && (
                <Container>
                  {!open && SelectedHeader(isLoading)}
                  <SEO />
                  <Grid container sx={styles.outer_box}>
                    {open && (
                      <Grid item sx={isLoading ? styles.displayStyle : {}}>
                        <Grid item sx={styles.logoExpand}>
                          <HeaderLogo
                            type={pageType}
                            open={open}
                            setOpen={setOpen}
                            setType={setPageType}
                            sideNavItems={sideNavItems}
                            level={1}
                            pathName={props.location.pathname}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            parentActiveItem={parentActiveItem}
                            setParentActiveItem={setParentActiveItem}
                          />
                          <IconButton
                            disableRipple
                            sx={styles.iconButton}
                            aria-label="Dark Mode"
                          >
                            <img
                              src={MoonIcon}
                              style={styles.moonIcon}
                              alt="dark_mode"
                            />
                          </IconButton>
                        </Grid>
                        <Grid item sx={isLoading ? styles.displayStyle : {}}>
                          <SideBar>
                            <NavMenu
                              sideNavItems={sideNavItems}
                              level={1}
                              type={pageType}
                              pathName={props.location.pathname}
                              setOpen={setOpen}
                              activeItem={activeItem}
                              setActiveItem={setActiveItem}
                              parentActiveItem={parentActiveItem}
                              setParentActiveItem={setParentActiveItem}
                            />
                          </SideBar>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box
                        sx={
                          !isSearchOpen
                            ? open
                              ? styles.center
                              : styles.centerBoxExpanded
                            : styles.displayStyle
                        }
                      >
                        <Box sx={styles.headerBox}>
                          {open && (
                            <Box
                              sx={
                                !isLoading
                                  ? styles.headerStyle
                                  : {
                                      ...styles.displayStyle,
                                      ...styles.headerStyle,
                                    }
                              }
                            >
                              {!isMobileView() && (
                                <Header
                                  selected={pageType}
                                  setSelected={setPageType}
                                  open={open}
                                  baseSettings = {baseSettings}
                                />
                              )}
                            </Box>
                          )}
                          {isLoading && !isMobile && <LoadingState />}
                        </Box>
                        <Box sx={open ? styles.contentStyle : {}}>
                          <Content>
                            {
                              <Box sx={isLoading ? styles.displayStyle : {}}>
                                <SecurityWrapper
                                  isDefaultLayout={true}
                                  isPrivateRoute={!checkisPublicRoute(
                                    type,
                                    pathname
                                  )}
                                  title={title}
                                >
                                  {props.children}
                                </SecurityWrapper>
                              </Box>
                            }
                          </Content>
                        </Box>
                        <Box
                          sx={
                            !isLoading
                              ? styles.footerBox
                              : {
                                  ...styles.footerBox,
                                  ...styles.displayStyle,
                                }
                          }
                        >
                          {<Footer />}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              )}
            </Box>
          )}
          {isCustomLayout(pathname) && (
            <SecurityWrapper isPreLogin={true}>
              <Box sx={isLoading ? styles.displayStyle : {}}>
                {props.children}
              </Box>
            </SecurityWrapper>
          )}
          {isCustomLayout(pathname) && isLoading && <LoadingState />}
        </AppContextProvider>
      </MDXWrapper>
    </ThemeWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
