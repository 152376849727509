const ApiSideNavItems = {
  DataAPI: {
    'Records': 1,
    'Query': 2,
    'Tokens': 3,
    'Detect': 4,
    'BIN Lookup': 5,
    'Audit': 6,
  },
  ManagementAPI: {
    'Accounts': 1,
    'Audit': 2,
    'Authentication': 3,
    'Connections': 5,
    'Data Types': 6,
    'Functions': 7,
    'Key Management': 8,
    'Roles': 9,
    'Pipelines': 10,
    'Policies': 11,
    'Service Accounts': 12,
    'Users': 13,
    'Vault Templates': 14,
    'Vaults': 15,
    'Workspaces': 16,
  }
};

module.exports = {ApiSideNavItems};