import React from 'react';
import theme from '../../../utils/theme';
import { TableRow as TableRowComponent } from '@mui/material';

const useStyles = {
  tableRowStyle: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    '&:last-child': {
      borderBottom: 'none',
    },
  },
};

const TableRow = (props) => {
  return (
    <TableRowComponent sx={useStyles.tableRowStyle}>
      {props.children}
    </TableRowComponent>
  );
};

export default TableRow;
