import * as React from 'react';
import Box from '@mui/material/Box';
import { MenuItem, MenuList, Grid } from '@mui/material';
import theme, { BREAKPOINTS } from '../../../utils/theme';
import messages from '../../../utils/messages';
import Typography from '../Typography';
import OpenStudioLink from '../../docs/StudioLinks';

const useStyles = {
  footer_box: {
    justifyContent: 'space-between',
  },
  footer_menu: {
    display: 'flex',
    padding: '0',
  },
  menu_list: {
    display: 'flex',
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '4px',
      paddingLeft: '20px',
      paddingBottom: '0px',
    },
  },
  footer_menu_list: {
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '4px',
      paddingLeft: '20px',
      paddingBottom: '20px',
    },
  },
  menu_item: {
    ':hover': {
      textDecorationLine: 'underline',
      textDecorationColor: theme.palette.primary.main,
      backgroundColor: 'inherit',
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: theme.spacing(0),
      minHeight: 'fit-content',
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(7),
    },
    [BREAKPOINTS.ANDROID]: {
      paddingRight: theme.spacing(5),
    },
  },
  last_menu_item: {
    [BREAKPOINTS.MOBILE]: {
      paddingTop: theme.spacing(0),
      minHeight: 'fit-content',
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      marginRight: theme.spacing(5),
    },
    [BREAKPOINTS.ANDROID]: {
      paddingRight: theme.spacing(0),
    },
  },
  copyright_menu_item: {
    [BREAKPOINTS.MOBILE]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
      minHeight: 'fit-content',
      paddingLeft: theme.spacing(0),
    },

    '&.Mui-disabled': {
      opacity: 1,
    },
  },
  default: {
    color: theme.palette.grey[700],
  },
};

export interface FooterProps {
  onSkyFlowClick?: () => void;
  onCookiePolicyClick?: () => void;
  onTermsAndConditionClick?: () => void;
}
const Footer: React.FC<FooterProps> = (props) => {
  const msg = messages['en'];
  const currentDate = new Date().getFullYear();

  const openSkyFlow = () => {
    window.open('https://www.skyflow.com/', '_blank');
    if (props.onSkyFlowClick) {
      props.onSkyFlowClick();
    }
  };

  const openCookiePolicy = () => {
    window.open('https://www.skyflow.com/cookie-policy', '_blank');
    if (props.onCookiePolicyClick) {
      props.onCookiePolicyClick();
    }
  };

  const openTermsAndConditions = () => {
    window.open('https://www.skyflow.com/terms-of-service', '_blank');
    if (props.onTermsAndConditionClick) {
      props.onTermsAndConditionClick();
    }
  };
  return (
    <Box>
      <Grid container sx={useStyles.footer_box}>
        <Grid item>
          <MenuList variant="selectedMenu" sx={useStyles.menu_list}>
            <MenuItem disabled sx={useStyles.copyright_menu_item}>
              <Typography type="DESKTOP_BODY_SMALL_TEXT" sx={useStyles.default}>
              {msg.copyRightSymbol} {currentDate} {msg.copyRight} 
              </Typography>
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid item>
          <MenuList
            variant="selectedMenu"
            sx={{ ...useStyles.menu_list, ...useStyles.footer_menu_list }}
          >
            <MenuItem sx={useStyles.menu_item} onClick={() => openSkyFlow()}>
              <Typography
                type="DESKTOP_ACTION_SECONDARY"
                sx={useStyles.default}
              >
                {msg.skyFlowSite}
              </Typography>
            </MenuItem>
            <MenuItem
              sx={useStyles.menu_item}
              onClick={() => openCookiePolicy()}
            >
              <Typography
                type="DESKTOP_ACTION_SECONDARY"
                sx={useStyles.default}
              >
                {msg.cookiePolicy}
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{ ...useStyles.menu_item, ...useStyles.last_menu_item }}
              onClick={() => openTermsAndConditions()}
            >
              <Typography
                type="DESKTOP_ACTION_SECONDARY"
                sx={useStyles.default}
              >
                {msg.termsAndCondition}
              </Typography>
            </MenuItem>
          </MenuList>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
