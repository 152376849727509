import { Box } from '@mui/system';
import React from 'react';

const useStyles = {
  boxStyle: {
    display: 'flex',
    gap: '0px 40px',
  },
  columnStyle: {
    maxWidth: 'calc(50% - 20px)',
  },
};

export interface TwoColumnLayoutProps {
  children?: any;
}

const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = (props) => {
  const { children } = props;
  return (
    <Box sx={useStyles.boxStyle}>
      {children.map((child, idx) => 
        <Box key={idx} sx={useStyles.columnStyle}>
          {child}
        </Box>
      )}
    </Box>
  );
};

export default TwoColumnLayout;
