import React, { useContext } from 'react';
import { Link, navigate } from 'gatsby';
import theme, { useGlobalStyles, COLORS } from '../../../utils/theme';
import { handleExternalLinks } from '../../../utils/helper';
import { AppContext } from '../../AppContextProvider';
import Routes from '../../../../fileNames.json';
export interface StylesDictionary {
  [Key: string]: React.CSSProperties;
}
const useStyles: StylesDictionary = {
  textUnderlineStyle: {
    textDecorationColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  linkStyle: {
    textDecoration: 'none',
    color: COLORS.LINK_COLOR,
    overflowWrap: 'break-word',
    cursor: 'pointer'
  },
  boxStyle: {
    width: '100%',
  },
};
export interface LinkWrapperProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  isTextDecorationOn?: boolean;
  isLinkFromCard?: boolean;
  maintainPrevPath?: boolean;
}
const checkLink = (href) => {
  return href.endsWith('/') || href.includes('#') ? href : href + '/';
};

const getLinkStyle = (isLinkFromCard) => {
  if (isLinkFromCard) {
    return { ...useStyles.boxStyle, ...useStyles.linkStyle };
  } else {
    return useStyles.linkStyle;
  }
};

const getLocation = () => {
  if (typeof window !== 'undefined') {
    const href = window.location.pathname;
    return href.endsWith('/') ? href : href + '/';
  } else {
    return '/';
  }
};

const handleClick = (history) => {
  if(history[0].split('#')[0] !== window.location.pathname)
  {
    window.location.assign(
      history[0],
    );
  }
  else {
    window.location.assign(
      '/',
    );
  }
};

const pathsToCheck = [
  '/apireference/',
  '/demos/',
  '/sdks/',
  '/record/',
  '/management/',
  '/sdkreference/'
];

export const isNewRoute = (data, path) => {
  const normalizedPath = path.endsWith('/') ? path : path + '/';
  if (pathsToCheck.includes(normalizedPath)) {
    return false;
  }
  if (Array.isArray(data)) {
    return !data.includes(normalizedPath);
  } else if (typeof data === 'string') {
    return data !== normalizedPath;
  }
};

const LinkWrapper: React.FC<LinkWrapperProps> = (props) => {
  const { href, isLinkFromCard, maintainPrevPath, type} = props;

  const state = useContext(AppContext);

  const internal = /^\/(?!\/)/.test(href);
  if (href && href.startsWith('mailto:')) {
    return (
      <a style={useGlobalStyles.DESKTOP_BODY_SMALL_TEXT}>{props.children}</a>
    );
  }
  if (!href || href.startsWith('#')) {
    return <>{props.children}</>;
  }
  if (maintainPrevPath && internal) {
    return (
      <Link
        to={checkLink(href)}
        state={{ prevPath: getLocation() }}
        activeStyle={{ color: 'transparent' }}
        style={
          props.isTextDecorationOn
            ? useStyles.textUnderlineStyle
            : useStyles.linkStyle
        }
      >
        {props.children}
      </Link>
    );
  }
  if (internal) {
    if(href) {
      return (
        <a
        onClick={()=>{
          if(isNewRoute(Routes, href)){
            window.location.assign(
            window.location.origin + checkLink(href),
            );
          }
          else {
          navigate(checkLink(href));
          state.setIsLoading(true);
          }  
        }}
        style={
          props.isTextDecorationOn
            ? useStyles.textUnderlineStyle
            : useStyles.linkStyle
        }
        data-testid="internalLink"
      >
        {props.children}
      </a>
      );
    }
    return <>{props.children}</>;
  } else {
    if(href === 'back' && (type === 'api' || type === 'sdk-ref'))
    {
      return (
        <a
          onClick={() => {handleClick(state.history);}}
          style={
            props.isTextDecorationOn
            ? useStyles.textUnderlineStyle
            : useStyles.linkStyle
          }
          data-testid="backLink"
        >
          {props.children}
        </a>
      );
    }
    return (
      <a
        onClick={() => {
          handleExternalLinks(href);
        }}
        data-testid="externalLink"
        style={getLinkStyle(isLinkFromCard)}
      >
        {props.children}
      </a>
    );
  }
};

LinkWrapper.defaultProps = {
  isTextDecorationOn: false,
  isLinkFromCard: false,
};

export default LinkWrapper;
