import React, { useState, useEffect } from 'react';
import { ChatButtonProps } from '../AskAI';

interface ChatButtonWrapperProps {
  baseSettings: ChatButtonProps['baseSettings'];
}
const ChatButtonWrapper: React.FC<ChatButtonWrapperProps> = ({ baseSettings }) => {
  const [ChatButton, setChatButton] = useState<React.FC<ChatButtonProps> | null>(null);

  useEffect(() => {
    const loadChatButton = async () => {
      if (typeof window !== 'undefined') {
        const { default: ChatButton } = await import('../AskAI');
        setChatButton(() => ChatButton);
      }
    };

    loadChatButton();
  }, []);

  if (!ChatButton) {
    return null;
  }

  return <ChatButton baseSettings={baseSettings} />;
};

export default ChatButtonWrapper;