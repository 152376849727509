import * as React from 'react';
import {
  AppBar,
  Box,
  MenuItem,
  MenuList,
  Toolbar,
  IconButton,
  Button,
} from '@mui/material';
import ArrowForwardIcon from '../../../../static/images/arrow-right.svg';
import theme, { COLORS, BREAKPOINTS } from '../../../utils/theme';
import messages from '../../../utils/messages';
import LinkWrapper from '../Link';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MoonIcon from '../../../../static/images/moon.svg';
import Typography from '../Typography';
import SearchField from '../SearchField';
import { isMobile } from 'react-device-detect';
import ROUTES from '../../../docs-config/src';
import { handleExternalLinks } from '../../../utils/helper';
import SecurityWrapper from '../SecurityWrapper';
import fileIcon from '../../../../static/images/file-text.svg';
import { CustomBaseSettings } from '../AskAI';

const useStyles = {
  box: {
    flexGrow: 1,
  },
  app_bar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    width: 'calc(100% - 8px)',
    [BREAKPOINTS.MOBILE]: {
      justifyContent: 'center',
    },
  },
  menu_list: {
    display: 'flex',
    padding: theme.spacing(0),
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    [BREAKPOINTS.MOBILE]: {
      margin: '0px',
      marginBlockStart: '0em',
      marginBlockEnd: '0em',
      paddingInlineStart: '0px',
      justifyContent: 'center',
    },
  },
  menu_item: {
    ':hover': {
      backgroundColor: 'inherit',
    },
    alignItems: 'unset',
    padding: theme.spacing(0),
    margin: '8px 16px',
    [BREAKPOINTS.MOBILE]: {
      margin: '0px',
      padding: '0px 14px',
      minHeight: '26px',
    },
  },
  active_text: {
    textDecorationLine: 'none',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
  },
  icon: {
    color: theme.palette.secondary.main,
    height: theme.spacing(4),
    width: theme.spacing(4),
    paddingLeft: '6px',
    margin: 'auto',
  },
  default: {
    color: COLORS.SECONDARY_DUSK,
    '&:hover': {
      textDecorationLine: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },
  iconStyle: {
    color: COLORS.PRIMARY_ACTIONS,
    '&:hover': {
      textDecorationColor: theme.palette.primary.main,
    },
    paddingTop:'3px',
    paddingLeft:'8px',
  },
  signInStyle: {
    color: COLORS.TEXT_TERTIARY,
    '&:hover': {
      textDecorationLine: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },
  signInbutton: {
    backgroundColor: COLORS.BACKGROUND_TERTIARY,
    borderRadius: '100px',
    paddingLeft: '20px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '0px 18px',
    alignItems: 'center',
    minHeight: '34px',
    maxHeight: '34px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_TERTIARY,
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  iconButton: {
    padding: '0px',
  },
  moonIcon: {
    width: '18px',
    height: '18px',
    display: 'none',
  },
  toolBarStyle: {
    maxHeight: '60px',
    [BREAKPOINTS.MEDIA_MIN_600]: {
      minHeight: '15px',
      justifyContent: 'center',
    },
  },
  searchBoxStyle: {
    paddingLeft:'1%',
    display: 'flex',
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
};
export interface HeaderProps {
  selected: 'docs' | 'api' | 'apireference' | 'sdk-ref';
  onSdkRefClick?: () => void;
  onApiRefClick?: () => void;
  onSignIn?: () => Record<string, unknown>;
  setSelected: (string) => void;
  open: boolean;
  rightNavItems?: [];
  baseSettings: CustomBaseSettings;
}

const Header: React.FC<HeaderProps> = (props) => {
  const msg = messages['en'];
  const openSdkReference = () => {
    props.setSelected('sdk-ref');
    if (props.onSdkRefClick) {
      props.onSdkRefClick();
    }
  };

  function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  const openApiReference = () => {
    props.setSelected('apireference');
    if (props.onApiRefClick) {
      props.onApiRefClick();
    }
  };

  const signIn = () => {
    if (props.onSignIn) {
      props.onSignIn();
    }
    const href = 'https://try.skyflow.com';
    handleExternalLinks(href);
  };
  return (
    <Box>
      <HideOnScroll>
        <AppBar position="static" sx={useStyles.app_bar}>
          <Toolbar sx={useStyles.toolBarStyle}>
            <Box sx={useStyles.searchBoxStyle}>
              <IconButton
                disableRipple
                sx={useStyles.iconButton}
                aria-label="Dark Mode"
              >
                <img
                  src={MoonIcon}
                  style={useStyles.moonIcon}
                  alt="dark_mode"
                />
              </IconButton>
              <SearchField pageType={props.selected} baseSettings = {props?.baseSettings}></SearchField>
            </Box>
            <MenuList variant="selectedMenu" sx={useStyles.menu_list}>
              <MenuItem
                sx={useStyles.menu_item}
                onClick={() => openApiReference()}
                disableRipple
              >
                <LinkWrapper href={ROUTES.apiReference.home}>
                  <Button disableRipple style={{ backgroundColor: 'transparent' }}>
                    <img src={fileIcon} />
                    <Typography
                    type="SMALL_SUB_HEADER"
                    sx={useStyles.iconStyle}
                    >
                      {msg.apiReference}
                    </Typography>
                  </Button>
                </LinkWrapper>
              </MenuItem>
              <MenuItem
                sx={useStyles.menu_item}
                onClick={() => openSdkReference()}
                disableRipple
              >
                <LinkWrapper href={ROUTES.sdks}>
                <Button disableRipple style={{ backgroundColor: 'transparent' }}>
                    <img src={fileIcon} style={{ paddingLeft:'20px' }}/>
                    <Typography
                     type="SMALL_SUB_HEADER"
                     sx={useStyles.iconStyle}
                  >
                    {msg.sdKPage}
                  </Typography>
                  </Button>
                </LinkWrapper>
              </MenuItem>
              <SecurityWrapper isHeader={true}>
              <MenuItem
                sx={useStyles.signInbutton}
                onClick={() => signIn()}
                disableRipple
              >
                <Typography
                  type="DESKTOP_ACTION_SECONDARY_MEDIUM"
                  sx={useStyles.signInStyle}
                >
                  {msg.signIn}
                </Typography>
                <img
                  src={ArrowForwardIcon}
                  style={useStyles.icon}
                  alt="arrow_right"
                />
              </MenuItem>
              </SecurityWrapper>
            </MenuList>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
};
export default React.memo(Header);
