import React from 'react';
import { TableCell } from '@mui/material';
import Typography from '../../common/Typography';
import { COLORS } from '../../../utils/theme';

const useStyles = {
  tableHeaderStyle: {
    backgroundColor: COLORS.PRIMARY_AIR,
    padding: '6px 10px'
  },
  tableHeaderTextStyle: {
    color: COLORS.SECONDARY_MIDNIGHT,
  },
};

const TableHeader = (props) => {
  return (
    <TableCell sx={useStyles.tableHeaderStyle}>
      <Typography
        type="DESKTOP_BODY_PARAGRAPH_MEDIUM"
        sx={useStyles.tableHeaderTextStyle}
      >
        {props.children}
      </Typography>
    </TableCell>
  );
};

export default TableHeader;
