import React, { ReactNode } from 'react';
import { Paper } from '@mui/material';
import theme, { COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';
import Info from '../../../../static/images/info.svg';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LinkWrapper from '../../common/Link';

const useStyles = {
  paperStyle: {
    backgroundColor: COLORS.PRIMARY_AIR,
    display: 'flex',
    marginBottom: '16px',
  },
  textStyle: {
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(0),
  },
  imgStyle: {
    padding: theme.spacing(3),
  },
};
const shortCodes = {
  p: (props) => 
    <Typography
      type="DESKTOP_BODY_SMALL_TEXT"
      sx={useStyles.textStyle}
      component="span"
    >
      {props.children}
    </Typography>
  ,
  a: (props) => <LinkWrapper href={props.href}>{props.children}</LinkWrapper>,
};

export interface NoticeProps {
  children?: any;
}

const getChildrenType = (item) => {
  if(typeof item !== 'string' && item.type.name === 'A')
  {
    if(typeof item.props.children === 'object')
    {
      let child;
      Array.isArray(item.props.children) ? child = `[${getChildren(item.props)}](${item.props.href})` 
        : child = `[${getChildrenType(item.props.children)}](${item.props.href})`;
      return child;
    }
    else {
      return `[${item.props.children}](${item.props.href})`;
    }
  }
  else if(typeof item !== 'string' && item.type === 'code')
  {
    return '`'+item.props.children+'`';
  }
  else if(typeof item !== 'string' && item.type === 'strong')
  {
    return '**'+item.props.children+'**';
  }
  else if(typeof item !== 'string' && item.type === 'em')
  {
    if(typeof item.props.children !== 'string')
    {
      return '***'+item.props.children.props.children+'***';
    }
    else {
      return '*'+item.props.children+'*';
    }
  }
  else {
    return item;
  }
};

const getChildren = (props) => {
  let children = '';
  if(typeof props.children !== 'string')
  {
    Array.isArray(props.children) ? props.children.forEach((item) => {
      children += getChildrenType(item);
    })
    : children += getChildrenType(props.children);
    return children;
  }
  else{
    return props.children;
  }
};

const Notice: React.FC<NoticeProps> = (props) => {
  const children = getChildren(props);

  return (
    <Paper sx={useStyles.paperStyle} elevation={0}>
      <img
        src={Info}
        width="18px"
        height="18px"
        style={useStyles.imgStyle}
        alt="info"
      />
      <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
        {children}
      </ReactMarkdown>
    </Paper>
  );
};

export default Notice;
