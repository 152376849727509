import React from 'react';
import theme, { COLORS, FONTS } from '../../../utils/theme';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import Typography from '../../common/Typography';

const Tag = ({ title, tagsList }) => {
  const selectedTagStyle = {
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '17px',
    marginRight: theme.spacing(1),
  };
  const root = {
    paddingLeft: '0px',
    marginBottom: '2px',
    alignItems: 'center',
  } as const;
  const titleStyle = {
    lineHeight: '25.6px',
    paddingRight: '10px',
  } as const;
  const getStyle = (selectedTag) => {
    let style = {};
    switch (selectedTag.toLowerCase()) {
      case 'new':
        style = {
          backgroundColor: COLORS.PRIMARY_FRONT,
          color: COLORS.TEXT_HIGH_EMPHASIS,
        };
        break;
      case 'fixed':
        style = {
          backgroundColor: COLORS.PRIMARY_DAY,
          color: COLORS.PRIMARY_WHITE,
        };
        break;
      case 'improved':
        style = {
          backgroundColor: COLORS.SECONDARY_DUSK,
          color: COLORS.PRIMARY_WHITE,
        };
        break;
      case 'beta':
        style = {
          backgroundColor: COLORS.PRIMARY_DAWN,
          color: COLORS.TEXT_HIGH_EMPHASIS,
        };
        break;
      default:
        style = {
          backgroundColor: COLORS.TEXT_LOW_EMPHASIS,
          color: COLORS.TEXT_HIGH_EMPHASIS,
        };
        break;
    }
    return { ...selectedTagStyle, ...style };
  };

  const getTextStyle = (selectedTag) => {
    let style = {};
    if (
      selectedTag.toLowerCase() === 'fixed' ||
      selectedTag.toLowerCase() === 'improved'
    ) {
      style = {
        color: COLORS.PRIMARY_WHITE,
      };
    } else {
      style = {
        color: COLORS.TEXT_HIGH_EMPHASIS,
      };
    }
    return { ...style, padding: '1px 4px 0px' } as const;
  };
  const getselectedTagString = (selectedTag) => {
    return selectedTag.toUpperCase();
  };
  return (
    <Grid container sx={root}>
      <Typography type="DESKTOP_BODY_PARAGRAPH" sx={titleStyle}>
        {title}
      </Typography>
      {tagsList &&
        tagsList.map((tag, index) => {
          return (
            <Box sx={getStyle(tag)} key={index}>
              <Typography type="DESKTOP_CODE_ALLCAPS" sx={getTextStyle(tag)}>
                {getselectedTagString(tag)}
              </Typography>
            </Box>
          );
        })}
    </Grid>
  );
};

export default Tag;
