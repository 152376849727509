import { isMobile } from 'react-device-detect';
import { FONT_LINE_HEIGHT, FONT_SIZES } from './theme';
import { CustomBaseSettings } from '../components/common/AskAI';

export const isMobileView = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 1024;
  } else {
    return isMobile;
  }
};

export const calculateCodeBlockLineHeight = () => {
  if (typeof window !== 'undefined' && window.innerWidth < 1024) {
    return FONT_LINE_HEIGHT.CODE_BLOCK * FONT_SIZES.CODE_SECONDARY_MOBILE;
  } else {
    return FONT_LINE_HEIGHT.CODE_BLOCK * FONT_SIZES.CODE_SECONDARY;
  }
};

export const handleExternalLinks = (href) => {
  const externalWindow = window.open(href, '');
  let path = '_blank';
  if (typeof window !== 'undefined') {
    path = window.location.href;
  }
  setTimeout(() => {
    const message = { referrer_type: 'SKYFLOW_DOCS', referrer_path: path };
    const domain = new URL(href).host;
    if (
      externalWindow &&
      domain &&
      !domain.startsWith('www') &&
      /^.*\.skyflow.*$/.test(domain)
    ) {
      externalWindow.postMessage(message, href);
    }
  }, 2000);
};
export const appendRouteToUrl = (routeToBeAppended) => {
  let selectionItem = '';
  selectionItem = routeToBeAppended.includes('#')
    ? routeToBeAppended
    : '#' + routeToBeAppended;
  if (selectionItem && selectionItem.includes('#')) {
    const search = '#';
    const replaceWith = '';
    const result = selectionItem.split(search).join(replaceWith);
    const ele = document.getElementById(result);
    if (ele) {
      if (/iPhone/.test(navigator.userAgent)) {
        ele.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      } else {
        ele.scrollIntoView();
      }
      window.history.pushState(
        null,
        '',
        window.location.href.split('#')[0] + selectionItem,
      );
    }
  }
};

let debounceTimeout;

export function updateHistoryState(newState) {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => {
    window.history.replaceState(null, '', '#' + newState);
  }, 250);
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
      return true;
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 == null || obj2 == null) {
      return false;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
  }

  for (let key in obj1) {
      if (!(key in obj2) || !deepEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  return true;
}
export function findMatchingApiName(components: { schemas: Record<string, any> }, pathData) {
  const pathDataResponseExample = JSON.parse(pathData.responseList.find(response => response.id === 'json_200').content);
  for (const [apiName, schema] of Object.entries(components.schemas)) {
      if (schema.example && deepEqual(schema.example, pathDataResponseExample)) {
          return apiName;
      }
  }
  return null;
}

export function isPathPrivate(path, data) {
  const findPath = (currentData, currentPath) => {
    for (const item of currentData) {
      const itemPath = currentPath ? `${currentPath}.${item.route}` : item.route;
      if (itemPath === path) {
        return item.public === true ? 'public' : 'private';
      }
      if (item.child) {
        const result = findPath(item.child, itemPath);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };
  return findPath(data, '');
}

export function getPathName(inputPath, routes: Record<string, string | Record<string, string>>) {
  const cleanInputPath = inputPath.split('#')[0].split('?')[0];
  const result = Object.entries(routes).find(([key, value]) =>
    typeof value === 'string' ? value === cleanInputPath : value[cleanInputPath]
  );
  return result ? result[0] : null;
}

export async function getSessionInfo(context: any): Promise<boolean> {
  let cookies: any = [];
  if (context.headers.get('cookie')) {
    cookies = context.headers.get('cookie').split(';');
  }

  let sessionId = '';
  for (const element of cookies) {
    if (element.trim().match('^session-id=')) {
      sessionId = element.replace('session-id=', '').trim();
    }
  }

  const session = async () => {
    return fetch(`${process.env.GATSBY_OKTA_DOMAIN_URL}/api/v1/sessions/${sessionId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `SSWS ${process.env.GATSBY_OKTA_API_KEY}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.status === 'ACTIVE') {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  if (sessionId && await session()) {
    return true;
  }

  return false;
}

export async function getBaseSettings(isAuthenticated: boolean): Promise<CustomBaseSettings> {
  return isAuthenticated
    ? {
      apiKey: process.env.GATSBY_INKEEP_PRIVATE_API_KEY!,
      integrationId: process.env.GATSBY_INKEEP_PRIVATE_INTEGRATION_ID || '',
      organizationId: process.env.GATSBY_INKEEP_ORGANIZATION_ID || '',

    }
    : {
      apiKey: process.env.GATSBY_INKEEP_PUBLIC_API_KEY!,
      integrationId: process.env.GATSBY_INKEEP_PUBLIC_INTEGRATION_ID || '',
      organizationId: process.env.GATSBY_INKEEP_ORGANIZATION_ID || '',

    };
}

export const filterSnippets = (snippets) => {
  return snippets.map((snippet) => {
    if (snippet.id === "shell_curl") {
      let content = snippet.content;
      const modifyUrlPart = (url) => {
        const [baseUrl, queryString] = url.split('?');
        if (!queryString) {
          return baseUrl;
        }
        const params = queryString.split('&');
        const validParams: any = [];
        const placeholderPattern = /^SOME_.*_VALUE$/;
        params.forEach(param => {
          const [key, value] = param.split('=');
          if (value && !placeholderPattern.test(value)) {
            validParams.push(`${key}=${value}`);
          }
        });
        return validParams.length ? `${baseUrl}?${validParams.join('&')}` : baseUrl;
      };
      const urlMatch = content.match(/--url '([^']+)'/);
      if (urlMatch) {
        const originalUrl = urlMatch[1];
        const modifiedUrl = modifyUrlPart(originalUrl);
        snippet.content = content.replace(originalUrl, modifiedUrl);
      }
    }
    return snippet;
  });
};