import React from 'react';


export const StoreContext = React.createContext({value:-1, setValue:(val)=>{}, defaultLabel:'', setLabel:(val)=>{}, preValue:0, setPreValue:(val)=>{}});

export default function SyncTabs({children}) {
  const [state, setState] = React.useState(0);
  const [label, setLabel] = React.useState('');
  const [preValue, setPreValue] = React.useState(0);

  return (
    <React.Fragment>
      <StoreContext.Provider value={{value: state, setValue: setState, defaultLabel: label, setLabel: setLabel, preValue:preValue, setPreValue:setPreValue}}>
        {children}
      </StoreContext.Provider>
    </React.Fragment>
  );
}
