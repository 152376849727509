import React from 'react';
import { TableCell } from '@mui/material';
import Typography from '../Typography';
import { BREAKPOINTS } from '../../../utils/theme';

const useStyles = {
  tableCellStyle: {
    borderBottom: 'none',
    verticalAlign: 'top',
    [BREAKPOINTS.MOBILE]: {
      minWidth: '135px',
    },
  },
};

const TableData = (props) => {
  return (
    <TableCell sx={useStyles.tableCellStyle}>
      <Typography type="DESKTOP_BODY_SMALL_TEXT">{props.children}</Typography>
    </TableCell>
  );
};

export default TableData;
